export const germanLangData = {
  MEDICAL_INFO_SYSTEM: 'Ärztliches Informationssystem',
  ACTIVE_EVENTS: 'Aktive Veranstaltungen',
  SELECT_AN_EVENT_TO_BROWSE_USERS: 'Wählen Sie ein Ereignis zum Durchsuchen der Benutzer aus.',
  BACK_TO_THE_EVENT_SELECTION: 'Zurück zur Veranstaltungsauswahl',
  IF_YOU_FORGOT_YOUR_PASSWORD: 'Wenn Sie Ihr Passwort vergessen haben ',
  LOGIN: 'Anmeldung',
  RESET_IT_HERE: 'hier zurücksetzen',
  CHANGE_LANGUAGE_TO: 'Sprache ändern auf',
  TEST_SYSTEM: 'TEST-SYSTEM',
  HELP: 'Hilfe',
  THIS_IS_TEST_ENV:
    'Dies ist eine Testumgebung, wenn Sie eine echte Anfrage einfügen möchten, gehen Sie zu',
  USER_OR_PASS_IS_INCORRECT: 'Benutzername oder Kennwort ist falsch',
  OPS: 'Ops',
  WE_DID_NOT_FIND: 'Wir haben nicht gefunden, was Sie gesucht haben',
  VIDEO_TUTORIALS: 'Video-Anleitungen',
  STILL_DIDNT_FIND: 'Immer noch nicht gefunden, was Sie suchen,',
  ASK_THE_SUPPORT_TEAM: 'Fragen Sie das Support-Team',
  LOGGED_IN_AS: 'Angemeldet als',
  MY_PROFILE: 'Mein Profil',
  LOGOUT: 'Abmelden',
  RETRIEVING_YOUR_ACCESS_INFO: 'Abrufen Ihrer Zugangsinformationen',
  LOGGED_IN_CAN_CLOSE: 'Wenn Sie angemeldet sind, können Sie diese Registerkarte jetzt schließen.',
  TOKEN_HAS_NOT_BEEN_SAVED:
    'Das Token wurde nicht richtig gespeichert, bitte wenden Sie sich an den Support',
  SHARE_POINT_LOGIN: 'SharePoint-Anmeldung',
  LOGIN_TO_SHARE_POINT: 'Bitte melden Sie sich bei SharePoint an, um fortzufahren',
  PASSWORD_RESET: 'Passwort zurücksetzen',
  RESET_PASSWORD: 'Passwort zurücksetzen',
  INSERT_YOUR_EMAIL_TO_RESET_PASS:
    'Bitte geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen',
  CANCEL: 'Abbrechen',
  CANCEL_UPPERCASE: 'ABBRUCHEN',
  RETURN_TO_LOGIN: 'Zurück zum Login',
  USERNAME: 'Benutzername',
  USERNAME_IS_REQUIRED: 'Benutzername ist erforderlich',
  PASSWORD: 'Kennwort',
  PASSWORD_IS_REQUIRED: 'Kennwort ist erforderlich',
  FIRST_NAME: 'Vorname',
  LAST_NAME: 'Nachname',
  REGISTER: 'Registrieren Sie',
  LOADING: 'Laden von',
  INSERT_NEW_PASS: 'Bitte geben Sie Ihr neues Passwort ein',
  VERIFYING_TOKEN: 'Verifizierendes Token',
  PASSWORD_DO_NOT_MATCH: 'Passwörter stimmen nicht überein',
  PASS_RESET_SUCCESSFUL: 'Passwort zurücksetzen erfolgreich',
  SAVE_NEW_PASS: 'Neues Passwort speichern',
  GO_TO_INBOX: 'Zum Posteingang',
  SELECT_ALL: 'Alle auswählen',
  DESELECT_ALL: 'Alle abwählen',
  DO_NOT_SHOW_AGAIN: ' Nicht wieder auftauchen',
  INQUIRY: 'Anfrage',
  ATTACHMENT_WITH_THIS_INQUIRY: '>Angaben zu dieser Anfrage',
  USER_DETAILS: 'user details',
  CLOSE: 'schließen',
  TEAMS: 'Mannschaften',
  TEAM: 'Mannschaft',
  SPECIALIZATION_IS: 'Die Spezialisierung ist',
  REQUIRED: 'erforderlich',
  RESULTS_PER_PAGE: 'Ergebnisse pro Seite',
  RESULTS_FOUND: 'Ergebnisse gefunden',
  EXPORT: 'Export',
  PERSONAL_SETTINGS: 'Persönliche Einstellungen',
  TEAM_SETTINGS: 'Team-Einstellungen',
  USER_SETTINGS_OF: 'BENUTZEREINSTELLUNGEN VON',
  SET_TEMPLATE: 'Vorlage einstellen',
  NOTIFICATIONS_WILL_BE_OVERWRITTEN:
    'Die Benachrichtigungen werden mit den aktuellen überschrieben',
  PREVIEW: 'Vorschau',
  NOTIFICATIONS: 'BENACHRICHTIGUNGEN',
  ROLE: 'ROLLE',
  TEMPLATE_NAME: 'TEMPLATEN-NAME',
  SAVE_THIS_USER_AS_A_TEMPLATE: 'Diesen Benutzer als Vorlage speichern',
  ADD_A_TEMPLATE_TO_YOUR_USER:
    'Fügen Sie eine Vorlage zu Ihrem Benutzer hinzu und kopieren Sie die Rolle und die Einstellungen für Benachrichtigungen',
  AUTH_AND_NOTIFY_TEMPLATE: 'Autorisierungs- und Benachrichtigungsvorlage',
  MY_ROLES: 'Meine Rollen',
  STATUS: 'STATUS',
  THIS_ROLE_IS_TEMP:
    'Diese Rolle ist vorübergehend, sie wurde aufgrund der Tatsache vergeben, dass dieser Benutzer ein Stellvertreter ist.',
  CREATED: 'GESCHAFFEN',
  APPROVED_REJECTED: 'GENEHMIGT/ABGELEHNT',
  APPROVED_REJECTED_BY: 'GENEHMIGT/ABGELEHNT VON',
  ARCHIVE: 'ARCHIV',
  SAVE: 'Speichern',
  IMMEDIATE_EFFECT: 'Sofortige Wirkung',
  OUT_OF_OFFICE_DEPUTY: 'Abwesenheitsvertretung',
  AUTO_ASSIGN_TO: 'AUTOMATISCHE ZUORDNUNG',
  GEOGRAPHY: 'GEOGRAPHIE',
  ALIGNMENT: 'AUSRICHTUNG',
  Alignments: 'Ausrichtungen',
  Address: 'Adresse',
  PHONE_FAX: 'Telefon / Fax',
  Email: 'E-Mail',
  Contacts: 'Kontakte',
  WORK_INFO: 'Informationen zur Arbeitstelle',
  LAST_NAME_IS: 'Der Nachname lautet',
  PERSONAL_INFO: 'Persönliche Informationen',
  USER: 'Benutzer',
  ID: 'ID',
  UPDATED_FROM: 'aktualisiert von',
  ON: 'auf',
  CRM: 'CRM',
  IMPORTED_FROM: 'importiert aus',
  CHANGE_PASS: 'Passwort ändern',
  NOTIFICATION_IN_TEMPLATE: 'Benachrichtigungen in Vorlage',
  CONTINUE: 'Weiter',
  DONT_SHOW_AGAIN: 'Zeigen Sie diese Nachricht nicht noch einmal',
  USING_THE_TABS_ON_THE: 'über die Registerkarten am oberen Rand des Bildschirms',
  PERSONAL_PROFILE_SETTINGS: 'Persönliche Profileinstellungen',
  AND_YOUR: 'und Ihre',
  YOU_CAN_SWITCH_BETWEEN: 'Sie können zwischen Ihrem',
  WELCOME_TO_YOUR_USER_PROFILE: 'Willkommen in Ihrem Benutzerprofil',
  SAVE_THIS_USER_AS: 'Diesen Benutzer als Vorlage speichern',
  INCLUDE_NOTIFY_SETTINGS: 'Enthält Benachrichtigungseinstellungen',
  INCLUDE_ROLES_SETTINGS: 'Einschließlich Rolleneinstellungen',
  ACTIVE_NOTIFICATIONS: 'AKTIVE BENACHRICHTIGUNGEN',
  SET_THIS_USER_AS_A_:
    'Legen Sie diese Benutzerberechtigung und Benachrichtigungen als neue Vorlage fest',
  REQUEST_AUTH: 'Autorisierung anfordern',
  REQUEST: 'Anfrage',
  FEEDBACK_SUPPORT: 'Feedback und Unterstützung',
  IF_YOU_STILL_CANNOT_FIND_WHAT_:
    'Wenn Sie immer noch nicht finden, was Sie suchen, fragen Sie das Support-Team! Auch wenn Sie einen Vorschlag für zukünftige Verbesserungen haben, sind Sie hier richtig.',
  TEMPLATE: 'Vorlage',
  SIGNATURE: 'Unterschriften',
  Disclaimers: 'Haftungsausschluss',
  Greetings: 'Begrüßung',
  CUSTOMIZE: 'ZUSTELLEN',
  SAVE_UPPERCASE: 'SPEICHERN',
  DELETE: 'LÖSCHEN',
  RESET_TO_DEFAULT: 'AUF STANDARD ZURÜCKSETZEN',
  AFTER_SIGNATURE: 'Nach der Unterschrift',
  BEFORE_SIGNATURE: 'Vor der Unterschrift',
  AFTER_ANSWER: 'Nach der Antwort',
  BEFORE_ANSWER: 'Vor der Antwort',
  AFTER_GREET: 'Nach der Begrüßung',
  BEFORE_GREET: 'Vor der Begrüßung',
  Any: 'Jede',
  HCP: 'Medizinischer Beruf',
  NO_HCP: 'Nichtmedizinischer Beruf',
  Position: 'Position',
  ON_LABEL: 'On Label',
  OFF_LABEL: 'Off Label',
  YOU_ARE_TRYING_TO_CUSTOMIZE: 'Sie versuchen, die Vorlage anzupassen.',
  YOU_DO_NOT_HAVE_THE_PERMISSON:
    '  Sie haben nicht die Erlaubnis, dies zu tun. Wenn Sie fortfahren möchten, wird eine Kopie der Vorlage erstellt und Sie nehmen dort die Änderungen vor.',
  CREATE_COPY: 'Kopie erstellen',
  RECEIVED_ON: 'Empfangen am',
  SENDER_EMAIL: 'E-Mail senden',
  SUBJECT: 'Gegenstand',
  UNSPAM: 'Unspam',
  UPLOAD: 'Hochladen',
  APPROVED: 'Genehmigt',
  DRAFT: 'Entwurf',
  SELECT_STATUS: 'Status auswählen',
  UPLOAD_AS: 'Upload als',
  TITLE: 'Titel',
  REPOSITORY: 'Repository',
  BROWSE: 'Durchsuchen',
  URL: 'URL',
  APPROVED_ON: 'Genehmigt am',
  AUTH_HOLDER: 'Zulassungsinhaber',
  AUTH_NUMBERS: 'Zulassungsnummer',
  APPROVED_FOR: 'Zugelassen für',
  SELECT_ONE_REGION: 'Wählen Sie eine Region',
  OWNED_BY: 'Im Besitz von',
  KEYWORDS: 'Schlüsselwörter',
  TOPICS: 'Themen',
  CATEGORIES: 'Kategorien',
  PRODUCTS: 'Produkte',
  PRODUCT: 'Produkt',
  LANGUAGE_IS_REQUIRED: 'Sprache is vereist',
  LANGUAGE: 'Sprache',
  TARGET_CUSTOMERS: 'Zielgruppen',
  TYPE_IS_REQUIRED: 'Type is vereist',
  TYPE: 'Art',
  TITLE_IS_REQUIRED: ' Titel is vereist',
  DEFAULT_REFERENCE_IS_CREATED_:
    'Der Standardverweis wird mit dem Vancouver-Stil erstellt. Wenn Sie dies außer Kraft setzen wollen, geben Sie den gesamten Verweis hier ein',
  REFERENCE: 'Referentie',
  DOI: 'DOI',
  PUBMED_ID: 'Pubmed-ID',
  PAGES: 'Seiten',
  ISSUE: 'Ausgabe',
  VOLUME: 'Volumen',
  JOURNAL: 'Zeitschrift',
  AUTHORS: 'Autoren',
  ABSTRACT: 'Abstrakt',
  REFERENCE_IS_REQUIRED: 'Referenz ist erforderlich',
  THIS_DOC_IS_APPROVED: 'Dieses Dokument ist fehlerhaft. Klicken Sie hier, um das',
  ORIGINAL_DOCUMENT_AND_: 'Originaldokument und fügen Sie Ihre Kommentare hinzu',
  EDIT_FILE: 'Datei bearbeiten',
  PROPERTIES: 'Eigenschaften',
  OPEN: 'Öffnen',
  LOCALIZED_DOCUMENTS: 'Lokalisierte Dokumente',
  ORIGINAL_DOCUMENTS: 'Ursprüngliches Dokument',
  INTERNAL_APPROVAL_NUMBER: 'Interne Zulassungsnummer',
  CREATED_BY: 'Erstellt von',
  EXPIRATION_DATE: 'Verfallsdatum',
  VERSION_NUMBER: 'Versionsnummer',
  NO_VERSION_AVAILABLE: 'Keine Version verfügbar',
  ARCHIVED_IF_NO_DRAFT: 'Archiviert: wenn keine Entwürfe/genehmigten Versionen vorhanden sind.',
  APPROVED_FOR_USERS: 'Zugelassen: für Benutzer.',
  DRAFT_FOR_AUTHORS_: 'Konzept: für Autoren und Herausgeber.',
  VERSION_LEGEND: 'Legende zur Version',
  VERSION: 'Version',
  SELECT_ONE_LANG: 'Wählen Sie eine Sprache',
  SELECT_ONE_TYPE: 'Wählen Sie einen Typ.',
  INTERNAL_NUMBER_REQUIRED: 'Interne Genehmigungsnummer ist erforderlich.',
  FILE: 'Datei',
  ATTACH_FILE: ' Datei anhängen',
  LITERATURE: 'Literatur',
  SLIDE_DECK: 'Foliensatz',
  PIL: 'Packungsbeilage',
  FAQ: 'FAQ',
  SRD: 'Standard-Antwortdokument',
  ADD_APPROVAL_STEP: 'Genehmigungsschritt hinzufügen',
  ADD_PARALLEL_STEP: 'Parallelen Schritt hinzufügen',
  DELETE_STEP: 'Schritt löschen',
  DELETE_ALL_PARALLEL_STEPS: 'Löschen Sie alle parallelen Schritte',
  CHANGE_STATUS_NAME: 'Name des Änderungsstatus',
  SET_REQUIRED_ROLE: 'Gewünschte Rolle festlegen',
  SET_TEAM_MEMBER: 'Teammitglied einstellen',
  YOU_DO_NOT_HAVE_THE_PERMISSION_TO_:
    'Sie haben nicht die Berechtigungen, diesen Prozess zu ändern..',
  SAVE_ROLE: 'ROLLE SPEICHERN',
  CREATE_NEW_WORKFLOW: 'NEUEN WORKFLOW ERSTELLEN',
  CREATE_NEW_STEP: 'NEUEN SCHRITT ERSTELLEN',
  PLEASE_ENTER_THE_NUMBER_OF_:
    'Bitte geben Sie die Anzahl der parallelen Genehmigungsschritte ein, d.h. Schritte, die gleichzeitig (nicht nacheinander) genehmigt werden. Wenn Sie keine parallelen Genehmigungsschritte haben, geben Sie 1 ein. Sie können die Anzahl der Genehmigungsschritte später jederzeit ändern.',
  APPROVAL_MUST_BE_AT_LEAST: 'Die Genehmigung muss mindestens',
  INSERT_THE_NEW_STATUS_NAME: 'Geben Sie den neuen Statusnamen ein',
  INSERT_THE_NEW_WORKFLOW_NAME: 'Geben Sie den neuen Workflow-Namen ein.',
  INSERT_THE_NEW_STEP_NAME: 'Geben Sie den neuen Schrittnamen ein.',
  NEW_DOCUMENT: 'Neues Dokument',
  CREATE: 'Erstellen Sie',
  DOCUMENT_MANAGEMENT: 'Dokumentenverwaltung',
  CONFIRM: 'Bestätigen Sie',
  CONFIRM_PROCESS: 'PROZESS BESTÄTIGEN',
  DEFINE_APPROVAL_STEPS_AND_TEAM: 'DEFINITION DER GENEHMIGUNGSSCHRITTE UND DES TEAMS',
  SET_DETAILS: 'DETAILS EINRICHTEN',
  SELECT_TYPE: 'TYP WÄHLEN',
  COMPLETE_LOCALIZATION_PROCESS: 'Vollständiger Lokalisierungsprozess',
  CREATE_NEW_VERSION: 'Neue Version erstellen',
  CREATE_NEW_DOCUMENT: 'Neues Dokument erstellen',
  IS_PRIVATE: ' Ist privat',
  SHARED_WITH_OTHER_TEAMS: ' Gemeinsam mit anderen Teams',
  PLEASE_ENTER_THE_NUMBER_IF_SEQ_:
    'Bitte geben Sie die Anzahl der erforderlichen aufeinanderfolgenden Genehmigungsschritte ein. Wenn zwei Genehmigungen parallel erfolgen, zählt dies als ein Schritt. Sie können die Anzahl der Genehmigungsschritte später jederzeit ändern.',
  DONE: 'Geschafft',
  SUBMIT_FOR_APPROVAL: 'Zur Genehmigung einreichen',
  YOU_HAVE_TO_CONFIRM_THE_: 'Sie müssen den Vorgang vor dem Hochladen bestätigen.',
  SELECT_YOUR_DEC_TYPE: 'Wählen Sie Ihren Dokumenttyp',
  TARGET_AUDIENCE: 'Das Zielpublikum',
  PROCESS_DETAILS: 'Details zum Prozess',
  START_DATE: 'Startdatum',
  DOC_DETAILS: 'Details zum Dokument',
  CONFIRMATION: 'Bestätigung',
  APPROVAL_PROCESS: 'Genehmigungsverfahren',
  SCHEDULED_EXP_DATE: 'Geplantes Verfallsdatum',
  SCHEDULED_PUBLISHING_DATE: 'Geplantes Veröffentlichungsdatum',
  NO_PROCESS_SELECTED: 'Kein Prozess ausgewählt',
  COUNTRIES: 'Länder',
  DOC_ID: 'Dokument-ID',
  THERAPEUTIC_AREA: 'Therapeutischer Bereich',
  FILTER: 'Filter',
  BACK: 'zurück',
  ATTACH: 'anfügen',
  COPY_ANSWER: 'Antwort kopieren',
  SIMILAR_INQUIRIES: 'Ähnliche Anfragen',
  SDR_FAQ: 'Standard-Antwortdokument/FAQ',
  SLIDE_DECKS: 'Foliensatz',
  MEDICAL_DOC: 'Medizinisches Dokument',
  SHOW: 'anzeigen',
  LOAD_MORE: 'Mehr laden',
  NO_RESULT_FOUND: 'Keine Ergebnisse gefunden.',
  CAN_BE_USED_TO_SEARCH_FOR_AN_:
    'kann verwendet werden, um nach einem ganzen Satz zu suchen, - kann verwendet werden, um aus den Suchergebnissen innerhalb des angegebenen Wortes auszuschließen',
  SEARCH_FOR_MEDICAL_SIMILAR_INQ: 'Suche nach medizinischen Dokumenten oder ähnlichen Anfragen',
  SEARCH: 'SUCHE',
  DRAFT_VERSION: 'Draft-Version',
  AVAILABLE_ACTIONS: 'Verfügbare Aktionen',
  OPEN_DOC: 'Dokument öffnen',
  UPLOAD_FILE: 'Datei hochladen',
  THE_DOCUMENT_IS_STILL_IN_APPROVAL_:
    'Das Dokument befindet sich noch in der Genehmigung. Wenn Sie den Dokumentinhalt ändern möchten, können Sie das Dokument in Sharepoint öffnen oder eine aktualisierte Version hochladen.',
  FINALIZE_LOCALIZATION: 'Lokalisierung abschließen',
  THIS_DOC_HAS_BEEN_LOCALIZED:
    'Dieses Dokument wurde lokalisiert, der Prozess muss vor der Genehmigung abgeschlossen werden.',
  Preview: 'Vorschau',
  PREVIEW_AND_SEND: 'Vorschau und senden',
  PREVIEW_AS_PDF: 'Vorschau als pdf',
  PUBLISHED_VERSION: 'Veröffentlichte Version',
  NEW_VERSION: 'Neue Version',
  THE_DOC_IS_STILL_IN_APPROVAL_:
    'Das Dokument befindet sich noch in der Genehmigung, wenn Sie den Dokumentinhalt ändern möchten, laden Sie eine andere Datei hoch.',
  OUT_TO: 'aus zu',
  NEXT: 'nächste',
  SELECTED_SLIDES: 'Ausgewählte Folien',
  GO_TO_SLIDE: 'Gehe zur Folie',
  PREV: 'Vorschau',
  SELECT_SLIDE: 'Folie auswählen',
  ANSWER: 'Antwort',
  BACK_TO_STANDARD_VIEW: 'Zurück zur Standardansicht.',
  UPLOAD_NEW_FILE: 'Neue Datei hochladen',
  CREATE_FILE: 'DATEI ERSTELLEN',
  EDITING_WORD_DOC: 'Word-Dokument bearbeiten',
  INSERT_FILE_NAME: 'Dateiname einfügen',
  PROCEED_WITH_THE_LINK_: 'Fahren Sie mit dem Link der Datei fort',
  A_NEW_WINDOW_WILL_BE_:
    ' Es wird ein neues Fenster mit einer leeren Datei geöffnet, schreiben Sie den Inhalt und schließen Sie die Registerkarte',
  INSERT_THE_NAME_FOR_:
    'Geben Sie den Namen für die Anlage ein, die Sie erstellen möchten, und fahren Sie mit der Dateierstellung fort',
  INSTRUCTIONS: 'Anweisungen',
  CREATE_NEW_WORD_FILE_FOR: 'Erstellen Sie eine neue Wortdatei für',
  FROM: 'von',
  YOU_CAN_FIND_HERE_THE_LAST_INQ_:
    'Hier finden Sie die letzten Anfragen, die Sie in das System eingegeben haben, sowohl eingegebene als auch noch nicht eingegebene. Sie können von Ihnen eingegebene Anfragen wiederverwenden, um weitere ähnliche Anfragen zu erstellen oder zu Anfragen zurückzukehren, die Sie noch nicht fertig eingegeben haben.',
  MY_DRAFT_INQ: 'Mein Untersuchungsentwurf',
  WARNING_CHANGES_NOT_SAVED: 'Warnung: Änderungen nicht gespeichert',
  PAGE_THAT_WILL_BE_LOST: 'Seite, die verloren geht, wenn Sie sich entscheiden, fortzufahren.',
  YOU_HAVE_UNUSED: 'Sie haben ungespeicherte Änderungen in ',
  ARE_YOU_SURE_YOU_WANT_TO_:
    'Sind Sie sicher, dass Sie diese Seite ohne Speichern verlassen wollen?',
  LEAVE_THIS_PAGE: 'Verlassen Sie diese Seite ',
  STAY_ON: 'Bleiben Sie auf',
  ASSIGNED_TO: 'Zugewiesen an',
  THIS_INQ_HAS_BEEND_INSERTED_: 'Diese Anfrage wurde während der Veranstaltung eingefügt.',
  STATUS_IN_APPROVAL: ' Status: In Genehmigung',
  THE_RESPONSIBLE_FOR_THE_ANSWER_:
    'Der Verantwortliche für die Genehmigung der Antwort muss die Antwort akzeptieren.',
  STATUS_DRAFT: 'Status: Entwurf',
  IF_YOU_WANT_CREATE_THE_ACTUAL_INQ_:
    'Wenn Sie die eigentliche Anfrage erstellen möchten, gehen Sie zur Registerkarte "Senden" und klicken Sie auf die Schaltfläche "Erstellen".',
  INQ_IMPORTED_FROM: 'Anfrage importiert von',
  SEND_NEW_ANSWER: 'NEUE ANTWORT SENDEN',
  RETURN_TO_INBOX: 'ZURÜCK ZUM POSTEINGANG',
  SUBMITTED_FOR_APPROVAL: 'Zur Genehmigung vorgelegt',
  ANSWER_SUBMITTED_ON: 'Antwort eingereicht am',
  BY: 'von',
  INQ_HAS_BEEN_CLOSED: 'Die Untersuchung wurde ohne eine Antwort abgeschlossen.',
  BCC: 'Bcc',
  ANSWER_GIVEN_ON: 'Antwort gegeben am ',
  ANSWER_SENT_ON: 'Antwort gesendet am ',
  INBOX: 'Posteingang',
  OPEN_ORIGINAL_INQ: 'Offene Original-Anfrage',
  CLOSE_ANSWER_NOT_NEEDED: 'Schließen: Antwort nicht erforderlich',
  HISTORY: 'Geschichte',
  ADD_A_NOTE: 'Eine Notiz hinzufügen',
  ADD_A_NOTE_INTO_: 'Fügen Sie eine Notiz in die Untersuchungshistorie ein.',
  SHARE_OR_START_A_FOLLOW_UP: 'Teilen oder starten Sie ein Follow-up',
  SEE_ALL_INQ_RELATED_TO: 'Sehen Sie alle Anfragen in Bezug auf',
  SRD_NEEDED: 'Standard-Antwortdokument erforderlich',
  ANSWER_UNAVAILABLE: 'Antwort nicht verfügbar',
  QUESTION: 'Frage',
  SUBMIT: 'UNTERNEHMEN',
  ANSWER_UPPERCASE: 'ANTWORT',
  DETAILS: 'EINZELHEITEN',
  SPLIT_INQ: 'Gespaltene Anfrage',
  ADD_QUESTION: 'Frage hinzufügen',
  ENTER_QUESTION: 'Frage eingeben',
  MARK_AS_ANSWERED: ' Als geantwortet markieren',
  TO_BE_ANSWERED: 'Beantwortet werden',
  FOLLOW_UP_RECEIVED: 'Nachbereitung erhalten',
  ERROR: 'Fehler',
  SHOW_ORIGINAL_MESSAGE: 'Originalnachricht anzeigen',
  SEND: 'Senden Sie',
  DO_NO_SEND: 'Nicht senden',
  OK: 'Okay',
  MAX_LENGTH_FOR_ZIP_CODE: ' Die maximale Länge für die Postleitzahl ist',
  CHARACTERS: 'Zeichen',
  ADDRESS_1_IS_: 'Adresse 1 lautet',
  LABEL_IS: '  Etikett ist',
  NO_QUESTION_INSERTED: 'Keine Frage eingefügt',
  WARNING: 'WARNUNG',
  FOLLOW_UP_IS_NOT_AVAILABLE_: 'Ein Follow-up ist für diese Interaktion nicht möglich. ',
  INSERT_INQ_TEXT: 'Anfragetext einfügen',
  SHOW_MORE: 'Mehr anzeigen',
  OUT_OF: 'aus',
  INQ_TO_BE_SENT: 'Zu sendende Anfragen',
  HIDE_INQ: 'Anfragen ausblenden',
  SHARE: 'Teilen Sie',
  HCP_PROFILE_OF: 'Medizinischer Beruf-PROFIL von',
  INQUIRER_DOES_NOT_HAVE_AN_EMAIL:
    'Füllen Sie unten alle Pflichtfelder und mindestens eine E-Mail-Adresse aus und speichern Sie dann, um eine Vorschau der DSGVO-Empfangsbenachrichtigung anzuzeigen.',
  CONFIRMATION_SENT_BY: 'Benachrichtigung gesendet von',
  COUNTRY_IS: 'Land ist',
  COUNTRY: 'Land',
  AT_LEAST_ONE_CONTACT_IS_: ' Mindestens eine Kontaktperson ist',
  EMAIL_IS: 'E-Mail ist',
  EMAIL_LABEL_IS: 'E-Mail-Etikett ist',
  PHONE_FAX_IS: 'Telefon-/Fax-Etikett ist ',
  PHONE_FAX_NUMBER_IS: ' Die Telefon-/Faxnummer lautet ',
  ADDRESS: 'Adresse',
  DELETE_PERSONAL_DATA: 'Persönliche Daten löschen',
  HCP_VERIFIED: 'Medizinischer Beruf verifiziert',
  KEY_OPINION_LEADER: 'Wichtiger Meinungsbildner',
  HOSPITAL_BASED: 'Krankenhaus',
  HCP_RELATED_INFORMATION: 'Medizinischer Beruf-BEZOGENE INFORMATIONEN',
  ACCEPT: 'Akzeptieren Sie',
  REJECT: 'Ablehnen',
  DO_YOU_WANT_TO_ACCEPT_THESE_Q: 'Wollen Sie diese Vorschläge annehmen?',
  SALES_REP: 'VERKAUFSREP',
  SUGGEST_A_TOPIC: 'Ein Thema vorschlagen',
  TEAM_SPECIFIC_FIELDS: 'TEAMSPEZIFISCHE FELDER',
  ADR_RELATED: 'ADR-bezogen',
  NOT_ADR_RELATED: 'Nicht ADR-bezogen',
  PLEASE_REVIEW_THE_MESSAGE_AND_:
    'Bitte überprüfen Sie die Nachricht und klassifizieren Sie sie als eine unerwünschte Arzneimittelwirkung oder nicht.',
  ADVERSE_DRUG_REACTION: 'Unerwünschte Arzneimittelwirkung',
  CHANGE_DETAILS: 'DETAILS ÄNDERN',
  YES: 'Ja',
  NO: 'Nein',
  DUE_DATE: 'Fälligkeitsdatum',
  CRITICAL: 'Kritisch',
  NORMAL: 'Normal',
  PRIORITY: 'Priorität',
  SELECT_DETAILS: 'AUSWAHL DER DETAILS',
  SAVE_REVIEW: 'Überprüfung speichern',
  SET_TO_ALL_INQ: 'AUF ALLE ANFRAGEN EINSTELLEN',
  PRODUCT_QUALITY_COMPLAINT: 'Beschwerde zur Produktqualität',
  PQC_PC_A_PRODUCT_:
    'PQC/PC Eine Produktqualitätsreklamation (QK) oder ein Problem ist definiert als jedes Anliegen, das einen Defekt oder eine Fehlfunktion eines Arzneimittels oder die Qualität, Leistung, Sicherheit eines Arzneimittels betrifft, z.B. veränderter Geruch und/oder Geschmack, schlechte oder fehlerhafte Verpackung, vermutete Kontamination usw.',
  OFF_LABEL_USE_OF_PHARMA___:
    'Off-Label-Verwendung von Arzneimitteln für eine nicht genehmigte Indikation oder in einer nicht genehmigten Altersgruppe, Dosierung oder Verabreichungsart.',
  A_RESPONSE_TO_A_MEDICINAL_:
    ' Eine Reaktion auf ein Arzneimittel, das schädlich und unbeabsichtigt ist und in einem kausalen Zusammenhang mit einem Arzneimittel und einem unerwünschten Ereignis steht, ist zumindest eine vernünftige Möglichkeit. Auch Sonderfälle (z.B. Schwangerschaftsfälle, Off-Label-Use, Überdosierung) müssen als ADR gekennzeichnet werden.',
  NORMAL_IS_7_WORKING_DAYS:
    'Normal sind 7 Arbeitstage, Hoch ist 48 Stunden und kritisch (patientenbezogen) sind 24 Stunden.',
  CATEGORY_AND_TOPIC_ARE: 'Kategorie und Thema sind',
  PRODUCT_IS: 'Produkt ist',
  DO_YOU_WANT_TO_ACCEPT_: 'Wollen Sie diese Vorschläge annehmen?',
  MERGE: 'zusammenführen',
  Duplicate: 'Doppelte',
  Other: 'Andere',
  Spam: 'Spam',
  CLOSE_INTERACTION: 'Enge Interaktion',
  RE_OPEN_THE_TARGET_: 'Nach dem Zusammenführen die Zielanfrage erneut öffnen ',
  MARK_AS_INTERNAL_: 'Als interne Nachricht für die bestehende Anfrage markieren.',
  MARK_AS_AN_ERROR_: ' Als Fehlermeldung für die bestehende Anfrage markieren.',
  THIS_MESSAGE_WILL_BE_EXISTING_:
    'Diese Nachricht wird als Folgeantwort auf eine bestehende Anfrage markiert.',
  THANK_YOU: 'Ich danke Ihnen.',
  AN_INTERNAL_MESSAGE: 'Eine interne Nachricht',
  AN_ERROR_MESSAGE: 'Eine Fehlermeldung',
  NEW_INQ: 'Neue Anfrage',
  A_FOLLOW_UP_RESPONSE: 'Eine Follow-up-Antwort',
  THIS_INQ_IS: 'Diese Untersuchung ist',
  SPLIT_INTERACTION: 'Gespaltene Interaktion',
  SAVE_AND_CLOSE: 'Speichern und schließen',
  SAVE_INQ: 'Anfrage speichern',
  REVIEW_THE_TEXT_AND: 'Überprüfen Sie den Text und die Kategorisierung und bestätigen Sie',
  CHOOSE_THE_RELEVANT_PRODUCT:
    'Wählen Sie das relevante Produkt, die Kategorie und das Thema für den ausgewählten Text',
  SELECT_THE_TEXT_THAT_:
    'Wählen Sie den Text aus, der die Frage zu einem Produkt/Thema identifiziert (schließen Sie keine Grüße, Unterschriften, Haftungsausschlüsse oder andere nicht relevante Informationen ein)',
  REPEAT_THE_PREVIOUS_: 'Wiederholen Sie die vorherigen Schritte, um weitere Anfragen hinzuzufügen',
  READ_MORE: 'Lesen Sie mehr',
  READ_LESS: 'Weniger lesen',
  DO_NOT_INCLUDE_:
    'keine Begrüßungen, Unterschriften, Verzichtserklärungen oder andere nicht relevante Informationen enthalten',
  SELECT_THE_TEXT_THAT_IDENT:
    'Wählen Sie den Text aus, der die Frage zu einem Produkt/Thema identifiziert',
  IF_YOU_HAVE_MULTIPLE_:
    'Wenn Sie mehrere Produkte oder Themen haben, muss die Anfrage aufgeteilt werden.',
  THE_FOLLOWING_PROCESS_:
    ' Der folgende Prozess wird Ihnen helfen, den relevanten Text für jedes Produkt/Thema zu identifizieren: ',
  EXPORT_TO_PDF: 'AUSFÜHRUNG NACH PDF',
  Added: 'hinzugefügt.',
  Deleted: 'Gelöscht',
  From: 'Von',
  To: 'Zu',
  BACK_TO_INBOX: 'Zurück zum Posteingang',
  PERMISSION_DENIED: 'Erlaubnis verweigert',
  THIS_INQ_IS_LOCKED_BY: 'Diese Untersuchung wird abgeschlossen von',
  FORCE_UNLOCK: 'GEWALT UNGESCHLOSSEN',
  INQUIRIES: 'Anfragen',
  Download: 'herunterladen',
  Event: 'Veranstaltung',
  INSERTED_BY: 'Eingefügt von',
  LAST_EDITED_ON: 'Zuletzt bearbeitet am',
  LAST_EDITED_BY: 'Zuletzt bearbeitet von',
  CREATED_ON: 'Erstellt am',
  TOPIC: 'Thema',
  TABLE_OPTIONS: 'Tabellen-Optionen',
  DEPARTMENT: 'Abteilung',
  User: 'Benutzer',
  ADD_THERAPEUTIC_AREA: 'Therapeutisches Gebiet hinzufügen',
  CREATE_THERAPEUTIC_AREA: 'Neues Therapiegebiet anlegen',
  ADD_NEW: 'Neu hinzufügen',
  SELECT_TEAM_TO_PROCEED: 'Wählen Sie Team zum Fortfahren',
  MIM: 'MIM',
  MA: 'MA',
  MSL: 'MSL',
  SALES: 'Vertrieb',
  CALL_CENTER: 'Callcenter',
  ALL: 'Alle',
  Warning: 'Warnung',
  clear: 'klar',
  THESE_CHANGES_WILL_BE_: 'Diese Änderungen werden auf die ausgewählten Benutzer angewendet.',
  INSERT_A_FOLLOW_UP_RESP: 'Einfügen einer Folgeantwort',
  INSERT_A_NOTE: 'Eine Notiz einfügen',
  MERGED_FROM: 'Fusioniert von',
  CHANGES: 'Änderungen',
  SHOW_LESS: 'weniger zeigen',
  COMPLETE: 'Vollständige',
  MISSING: 'Fehlende',
  CLOSE_AS_FORWARDED: 'Schließen wie weitergeleitet',
  CLOSE_EXT_TEAM: 'Schließen Sie die Anfrage wie an das externe Team weitergeleitet',
  THE_INQ_BELONG_TO_A_:
    'Diese Untersuchung gehört zu einem Land, das in die Zuständigkeit eines externen Teams fällt. Die Anfrage wird dann automatisch an dieses Team weitergeleitet.',
  RESOLVE: 'Auflösung',
  TERRITORIES: 'Gebiete',
  EXTERNAL_COMMUNICATION: 'EXTERNE KOMMUNIKATION',
  FOR_INQ_COMING_DIRECTLY_:
    'Bei Anfragen, die direkt vom Vertreter des Gesundheitswesens per E-Mail kommen, senden Sie automatisch eine Benachrichtigung, um ihn über den Eingang der Anfrage zu informieren.',
  INTERNAL_COMMUNICATION: 'INTERNE KOMMUNIKATION',
  SALESREP_RECEIVES_:
    'Der Vertriebsmitarbeiter erhält nur Benachrichtigungen, dass die Anfrage beantwortet wurde.',
  SALESREP_RECEIVES_FULL_ANSWER_:
    'Vertriebsmitarbeiter erhält vollständige Antwort für On Label-Anfragen',
  SALESREP_RECEIVES_ONLY_NOTIF_:
    'Der Vertriebsmitarbeiter erhält nur Benachrichtigungen, dass die Anfrage beantwortet wurde.',
  Name: 'Name',
  Module: 'Modul',
  Function: 'Funktion',
  LAST_RUN: 'Letzter Lauf',
  End: 'Ende',
  Every: 'Alle',
  Period: 'Zeitraum',
  Active: 'Aktiv',
  Resolved: 'Beschlossen',
  ERROR_PREVIEW: 'Fehler-Vorschau',
  FAILURE_COUNT: 'Ausfallzählung',
  COMPLETED_ON: 'Abgeschlossen am',
  STARTED_ON: 'Angefangen am',
  TASK_NAME: 'Name der aufgabe',
  PRODUCT_SPECIFIC: 'PRODUKTSPEZIFISCH',
  THERAPEUTIC_AREAS: 'THERAPEUTISCHE BEREICHE',
  PRODUCT_GROUPS: 'PRODUKTGRUPPEN',
  LICENSING_PARTNER: 'LIZENZPARTNER',
  PRODUCT_CODE: 'PRODUKT CODE',
  EXTERNAL_ID: 'Externe ID',
  OBJECT_TYPE: 'Objekttyp',
  MIGRATION_NAME: 'Name der Migration',
  MIGRATION_ERRORS: 'Fehler bei der Migration',
  ADD_SALES_REP: 'Vertriebsmitarbeiter hinzufügen',
  ASSIGNED_TO_MSL: 'DER MSL ZUGEORDNET',
  NOT_ASSIGNED: 'Nicht zugeordnet',
  ASSIGNED_TO_OTHER_MSL: 'Anderen MSL zugeteilt',
  ASSIGNED_TO_THIS_MSL: 'Diesem MSL zugeordnet',
  SHOW_ONLY: 'Nur anzeigen',
  SALES_REP_LIST: 'Liste der Handelsvertreter',
  ACTIVE_TASK: 'Aufgabe aktivieren',
  CHOOSE_USER_AND_SET_THE_SHIFT_: 'Benutzer auswählen und die Schicht für das Ereignis festlegen',
  MANAGE_SHIFTS: 'SHIFTS VERWALTEN',
  CREATE_NEW_USER: 'Neuen Benutzer anlegen',
  THIS_TEMPLATE_WILL_BE_AUTO_: 'Diese Vorlage wird automatisch zu Ihrem Teammitglied hinzugefügt.',
  TEMPLATE_FOR: 'Vorlage für',
  Day: 'Tag',
  Week: 'Woche',
  Month: 'Monat',
  Today: 'Heute',
  Archive: 'Archiv',
  PARTICIPANTS: 'TEILNEHMER',
  EVENT_MANAGEMENT: 'VERANSTALTUNGSMANAGEMENT',
  ADD_TASK: 'Aufgabe hinzufügen',
  EDIT_TASK: 'Aufgabe bearbeiten',
  IS_ACTIVE: 'Ist aktiv',
  Months: 'Monate',
  Days: 'Tage',
  Hours: 'Stunden',
  Minutes: 'Protokoll',
  HCP_ADMINISTRATION: 'Medizinischer Beruf-VERWALTUNG',
  AUTH_REQ_ADMINISTRATION: 'Auth-Anforderungsverwaltung',
  THE_PRODUCT_WILL_BE_HIGHLIGHTED_IN_:
    'Das Produkt wird in den ausgewählten therapeutischen Bereichen hervorgehoben.',
  PRODUCT_GROUPS_DEFINE_:
    'Produktgruppen definieren das Team, das für die Beantwortung dieser Produktanfragen verantwortlich ist.',
  USER_ADMINISTRATION: 'Benutzerverwaltung',
  IS_PRODUCT_SPECIFIC: 'Ist produktspezifisch',
  USER_TEMPLATES_ADMIN: 'Verwaltung von Benutzervorlagen',
  SET_DEFAULT_VALUES: 'STANDARDWERTE EINSTELLEN',
  SET_DEFAULT_VALUES_FOR_: 'Standardwerte für das Ereignis festlegen',
  INQUIRY_DEFAULT_VAL: 'Standardwerte für die Anfrage',
  CREATE_NEW_EVENT: 'NEUE VERANSTALTUNG ERSTELLEN',
  EDIT_EVENT: 'Ereignis bearbeiten',
  SET_DETAILS_TO_CREATE_NEW_EVENT: 'Details zur Erstellung eines neuen Ereignisses festlegen',
  ADD_DOC_LINKED_TO_: 'Dokumente hinzufügen, die mit dem Ereignis verknüpft sind',
  INC_CHANNEL: 'Eingehender Kanal',
  CHANGE_DETAILS_OF_THE_EVENT: 'Details der Veranstaltung ändern',
  DESCRIPTION: 'Beschreibung',
  CREATE_APPROVAL_PROCESS: 'Wählen Sie Genehmigungsprozess',
  CREATE_NEW: 'Neu erstellen',
  CUSTOMIZE_PROCESS: 'Prozess anpassen',
  SELECTED_PROCESS: 'Ausgewählter Prozess',
  CLONE: 'Klonen',
  PREFERENCES_TITLE: 'Einstellungen',
  NOTIFICATIONS_TITLE: 'Benachrichtigungen',
  TEMPLATES: 'Vorlagen',
  LETTER_TEMPLATES: 'Briefvorlage',
  USER_TEMPLATES: 'Benutzervorlagen',
  TEAM_MEMBERS: 'Teammitglieder',
  NEW_PASSWORD: 'Neues Kennwort',
  REPEAT_NEW_PASSWORD: 'Wiederhole das neue Passwort',
  AT_LEAST_1_LOWERCASE_CHARACTER: 'Mindestens 1 Kleinbuchstabe',
  AT_LEAST_1_UPPERCASE_CHARACTER: 'Mindestens 1 Großbuchstabe',
  AT_LEAST_1_DIGIT: 'Mindestens 1 Ziffer',
  AT_LEAST_1_SPECIAL_CHARACTER: 'Mindestens 1 Sonderzeichen',
  MINIMUM_8_CHARACTERS: 'Mindestens 8 Zeichen',
  SHOULD_NOT_CONTAIN_EMAIL_OR_NAMES: 'Sollte keine E-Mail oder Namen enthalten',
  SHOULD_MATCH_PASSWORD: 'Sollte mit dem Passwort übereinstimmen',
  USER_MANUAL: 'Benutzerhandbuch',
  SUPPORT: 'Unterstützung',
  BACK_TO_LOGIN: 'Zurück zur Anmeldung',
  PASSWORD_CONFIRMATION: 'Passwort Bestätigung',
  HAVE_AN_ACCOUNT: 'Haben Sie schon einen Account?',
  Submit: 'Übermitteln',
  Gender: 'Geschlecht',
  PROFESSION: 'Beruf',
  MEDICAL_INQ_ROLES: 'Zuständig für die Beantwortung medizinischer Anfragen',
  MEDICAL_INFO_MANAGER: 'Medizinischer Informationsdienst Manager',
  MEDICAL_SCIENCE_LIAISON: 'Medical Science Liaison',
  NOT_PROCESSING_INQUIRIES: 'Anfragen nicht bearbeiten',
  ACTIVE_HEALTH_CARE_SPEC: 'Aktiver Gesundheitsspezialist',
  NOT_ACTIVE_HEALTH_CARE_SPEC: 'Nicht aktiver Gesundheitsspezialist',
  PASS_IS_REQUIRED: 'Passwort wird benötigt!',
  EMAIL_IS_REQUIRED: 'E-Mail ist erforderlich!',
  VALID_EMAIL: 'Bitte geben Sie eine gültige E-Mail-Adresse ein!',
  FIELD_IS_REQUIRED: 'Dieses Feld wird benötigt!',
  ONLY_LETTERS: 'Bitte geben Sie nur Buchstaben ein!',
  ARE_YOU_daiichi_EMPLOYEE: 'Sind Sie Mitarbeiter von Daiichi Sankyo?',
  here: 'Hier',
  CREATE_AN_ACCOUNT: 'Ein Konto erstellen',
  FORGOT: 'Vergessen?',
  Medical: 'Medizinisch',
  Medical_not_resp: 'Nicht zuständig für die Beantwortung medizinischer Anfragen',
  Commercial: 'Kommerziell',
  Market_access: 'Marktzugang',
  Pharmacovigilance: 'Pharmakovigilanz',
  PR: 'PR',
  Please_specify: 'Bitte angeben',
  Medical_information_manager: 'Manager für medizinische Informationen',
  Medical_advisor: 'Medizinischer Berater',
  Are_Medical_inquiries: 'Sind Sie für die Beantwortung medizinischer Anfragen verantwortlich?',
  Are_Health_care_professional: 'Sind Sie ein praktizierender Arzt?',
  Health_care_Industry: 'Gesundheitsindustrie',
  Health_Authority: 'Gesundheitsbehörde',
  Journalist: 'Journalist/in',
  Lawyer: 'Anwält/in',
  Patient: 'Patient',
  Payor: 'Payor',
  Scientist: 'Naturwissenschaftler/in',
  Angiologist: 'Angiologe/in',
  Cardiologist: 'Kardiologe/in',
  Cardiovascular_Surgeon: 'Herz-Kreislauf-Chirurg',
  Dentist: 'Zahnarzt/Zahnärztin',
  Gastroenterologist: 'Gastroenterologe/in',
  General_Practitioner: 'Allgemeinarzt',
  Geriatrician: 'Geriater/in',
  Gynecologist: 'Gynäkologe/in',
  Haematologist: 'Hämatologe',
  Internist: 'Internist/in',
  Medical_Student: 'Medizinstudent',
  Nephrologist: 'Nephrologe/in',
  Neurologist: 'Neurologe/in',
  Neurosurgeon: 'Neurochirurg/in',
  Nurse: 'Krankenschwester',
  Oncologist: 'Onkologe',
  Oncology_Nurse: 'Onkologische Krankenschwester',
  Other_HCP: 'Andere Medizinischer Beruf',
  Other_Surgeon: 'Anderer Chirurg',
  Pediatrician: 'Kinderarzt/in',
  Pharmacist: 'Apotheker/in',
  Pharmacologist: 'Pharmakologe/in',
  Pharmacy_Technician: 'Pharmazeutisch-technische Assistent/in',
  Pneumologist: 'Pneumologe',
  Radiologist: 'Radiologe/in',
  Rheumatologist: 'Rheumatologe/in',
  USER_PASS_INCORRECT: 'Benutzername oder Passwort ist falsch',
  SUBMITTED_REQUEST: 'Ihre Anfrage wurde erfolgreich eingereicht.',
  Reset: 'Zurücksetzen',
  Reset_pass: 'Passwort zurücksetzen',
  New_pass: 'Neues Kennwort',
  RESET_PASS_EMAIL: 'Ein Link zum Zurücksetzen des Passworts wurde an Ihre E-Mail gesendet',
  NEW_PASS_SUCCESS: 'Ihr Passwort wurde erfolgreich zurückgesetzt',
  SOMETHING_WENT_WRONG: 'Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.',
  EVENTS: 'Veranstaltungen',
  Login_here: 'Hier anmelden',
  REGISTRATION: 'Anmeldung',
  Create_an_account: 'Ein Konto erstellen',
  NO_RECORDS: 'Keine Aufzeichnungen',
  INTERACTION: 'Interaktion',
  SPECIALIZATION: 'Spezialisierung',
  SPECIALIZATION_TYPE: 'Spezialisierungstyp',
  SAVED: 'Gespeichert',
  SAVING: 'speichern',
  TYPING: 'Tippen',
  CREATE_NEW_INTERACTION: 'Neue Interaktion erstellen',
  FILTER_BY: 'Filtern nach',
  SEND_EMAIL: 'E-Mail senden',
  SAVE_WITHOUT_EMAIL: 'Ohne E-Mail speichern',
  SUBMIT_TO_INBOX: 'In den Posteingang senden',
  Create_my_acc: 'Erstelle meinen Account',
  NEW_QUESTION: 'Neue Anfrage',
  LABEL: 'Etikette',
  SELECT_A_LABEL: 'Wählen Sie ein Etikett',
  CITY: 'Stadt',
  SUGGEST_A_NEW_CATEGORY_OR_SELECT_:
    'Schlagen Sie eine neue Kategorie vor oder wählen Sie eine vorhandene aus',
  SUGGEST_CATEGORY_OR_TOPIC: 'Schlagen Sie eine neue Kategorie oder ein neues Thema vor',
  URGENT: 'Dringend',
  EXISTING_CATEGORY: 'Bestehende Kategorie',
  NEW_CATEGORY_NAME: 'Neuer Kategoriename',
  NEW_TOPIC_NAME: 'Neuer Themenname',
  CATEGORY: 'Kategorie',
  MSLA: 'MSLA',
  OUT_CHANNEL: 'Ausgehender Kanal',
  LOGIN_TO_CONTINUE: 'Melden Sie sich an, um mit Madjenta fortzufahren',
  Email_address: 'E-Mail-Addresse',
  FORGOT_PASS: 'Passwort vergessen?',
  Remember_me: 'Behalte mich in Erinnerung',
  YOU_WILL_BE_NOTIFIED:
    'Bestätigungs-E-Mail wurde mit weiteren Anweisungen für Ihre Registrierung gesendet',
  SUCCESS_EMAIL_CONFIRMATION: 'Ihre E-Mail wurde erfolgreich bestätigt.',
  SUCCESS_EMAIL_CONFIRMATION_SUB: 'Sie werden benachrichtigt, wenn Ihre Anfrage geprüft wurde',
  FAILED_EMAIL_CONFIRMATION: 'Wir können Ihre E-Mail nicht bestätigen.',
  FAILED_EMAIL_CONFIRMATION_SUB:
    'Bitte versuchen Sie es erneut. Wenn das Problem weiterhin besteht, schreiben Sie an den Support mit der Beschreibung des Problems',
  RESET_FILTERS: 'Filter zurücksetzen',
  NEW_PASS_RESET_SUCCESS: 'Passwort erfolgreich zurückgesetzt!',
  Cardiovascular: 'Herz-Kreislauf',
  Oncology: 'Onkologie',
  Due_On: 'Fällig am',
  Follow_Up_Sent_On: 'Follow-up gesendet',
  Follow_Up_Received_On: 'Follow-up empfangen am',
  Closed_On: 'Geschlossen',
  Reopened_On: 'Wiedereröffnet am',
  Inquiry_ID: 'Anfrage-ID',
  Search: 'Suche',
  Assigned_to_Now: 'Zugewiesen an (jetzt)',
  Edited_By: 'Bearbeitet von',
  Assigned_to_Any_Time: 'Zugewiesen an (jederzeit)',
  Closed: 'Geschlossen',
  Foreign: 'Ausländisch',
  Complex_search_Sentence_: 'Komplexe Suche: Satz: "zu suchender Satz", nicht: -Wort',
  Search_inquiries: 'Suchanfragen',
  SEARCH_REPOSITORY: 'Repository durchsuchen',
  MIR_INBOX: 'MIR INBOX',
  Filters: 'Filter',
  Active_Filters: 'Aktive Filter',
  CONTACT_SUPPORT: 'Kontaktieren Sie Support',
  MedicalInformationRequests: 'Anfragen (pdf)',
  UserProfileNavigation: 'Benutzerprofil (pdf)',
  UserManualChanges: 'Änderungen im Benutzerhandbuch (docx)',
  iPad_How_To: 'iPad (pdf)',
  MedIS_Assign_Inquiry: 'Anfrage zuweisen',
  MedIS_Documentation_Approval_Process: 'Genehmigungsprozess für die Dokumentation',
  MedIS_Documentation_Overview: 'Dokumentationsübersicht',
  MedIS_Inquiry_Documentation: 'Anfragedokumentation',
  MedIS_MIR_Inbox_Filters: 'MIR-Posteingangsfilter',
  MedIS_Merge_MIR: 'MIR zusammenführen',
  MedIS_New_Documentation: 'Neue Dokumentation',
  MedIS_New_Inquiry: 'Neue Anfrage',
  MedIS_Out_Of_Office: 'Abwesenheitsvertretung',
  MedIS_Prepare_Custom_Answer: 'Bereiten Sie eine benutzerdefinierte Antwort vor',
  MedIS_Split_MIR_Part_I: 'Split MIR Teil I.',
  MedIS_Split_MIR_Part_II: 'Split MIR Teil II',
  I_AGREE_TO: 'Genau',
  TermsAndConditionsLink: 'Geschäftsbedingungen',
  Title: 'Titel',
  INTERACTION_TITLE: 'Interaktionstitel',
  Move: 'Bewegung',
  Left: 'Links',
  Right: 'Richtig',
  Requestor: 'Antragstellerin',
  Assigned_To: 'Zugewiesen an',
  Status: 'Status',
  Note: 'Hinweis',
  Confirm: 'Bestätigen',
  rejectConfirm: 'Möchten Sie die Anfrage wirklich ablehnen?',
  approveConfirm: 'Sind Sie sicher, dass Sie die Anfrage genehmigen möchten?',
  newUser: 'Neuer Benutzer',
  editUser: 'Benutzer bearbeiten',
  deleteUser: 'Benutzer löschen',
  newProduct: 'Neues Produkt',
  editProduct: 'Produkt bearbeiten',
  deleteProduct: 'Produkt löschen',
  newCategory: 'Neue Kategorie',
  editCategory: 'Kategorie bearbeiten',
  deleteCategory: 'Kategorie löschen',
  newTopic: 'Neues Thema',
  editTopic: 'Thema bearbeiten',
  deleteTopic: 'Thema löschen',
  userRequest: 'Benutzeranfrage',
  productRequest: 'Produktanfrage',
  categoryRequest: 'Kategorieanforderung',
  categoryTopicRequest: 'Kategorie- / Themenanfrage',
  topicRequest: 'Themenanfrage',
  Suggestion: 'Vorschlag',
  tradeName: 'Handelsname',
  activeSubstance: 'Aktive Substanz',
  productName: 'Produktname',
  productSpecific: 'Ist es produktspezifisch?',
  EMAIL_IN_USE: 'Bei dieser E-Mail ist ein Konto vorhanden',
  fromDate: 'Ab Datum',
  toDate: 'Miteinander ausgehen',
  applyFilter: 'Filter anwenden',
  requestReceivedFrom: 'Anfrage erhalten von',
  on: 'auf',
  at: 'beim',
  to: 'zu',
  add: 'hinzufügen',
  from: 'von',
  approve: 'genehmigen',
  reject: 'ablehnen',
  withTheFollowingRole: 'mit der folgenden Rolle',
  forTheFollowingTeam: 'für das folgende Team',
  request: 'Anfrage',
  submittedBy: 'eingereicht von',
  INVALID_OR_EXPIRED_RESET_REQUEST:
    'Die Anforderung zum Zurücksetzen des Kennworts ist ungültig oder abgelaufen',
  PASS_IS_RECENT: 'Das Passwort wurde kürzlich verwendet',
  Assigned_to_me: 'Mir zugewiesen',
  My_drafts: 'Meine Entwürfe',
  My_open_inquiries: 'Meine offenen Anfragen',
  My_closed_inquiries: 'Meine geschlossenen Anfragen',
  Filter_inquiries_by: 'Filtern Sie Anfragen nach',
  New_inq: 'Neue Anfrage',
  Inq_inbox: 'Anfragen Posteingang',
  REPORTS: 'Berichte',
  My_teams_open_inq: 'Die offenen Anfragen meines Teams',
  My_teams_closed_inq: 'Die geschlossenen Anfragen meines Teams',
  All_teams_open_inq: 'Alle Teams öffnen Anfragen',
  All_teams_closed_inq: 'Alle Teams haben Anfragen geschlossen',
  Overdue: 'Überfällig',
  Due_this_week: 'Fällig diese Woche',
  Pending_approval: 'Ausstehende Genehmigung',
  ADMINISTRATION: 'Verwaltung',
  Show_all: 'Zeige alles',
  In_progress: 'In Bearbeitung',
  Table_Settings_Columns: 'Tabelleneinstellungen - Spalten',
  Change_column_visibility: 'Spaltensichtbarkeit ändern',
  Reassign_to: 'Neu zuweisen zu',
  set_priority: 'Priorität setzen',
  set_status: 'Status einstellen',
  Spam_list: 'Spam-Liste',
  Institution: 'Institution',
  DUE_ON: 'Fällig am',
  Profile_settings: 'Profileinstellungen',
  Preferences: 'Einstellungen',
  Role_settings: 'Rolleneinstellungen',
  Notification_settings: 'Benachrichtigungseinstellungen',
  User_profile: 'Benutzerprofil',
  Select_person: 'Person auswählen',
  FROM_DATE: 'Von Datum',
  TO_DATE: 'Bis Datum',
  JOB_DESC: 'Arbeitsbeschreibung',
  INQ_Management: 'Anfrageverwaltung',
  DOCS_Management: 'Dokumenten-Management',
  USER_Management: 'Benutzerverwaltung',
  Define_Frequency: 'Frequenz definieren',
  All_teams_all_inq: 'Alle Anfragen für alle Teams',
  My_teams_all_inq: 'Alle Anfragen an mein Team',
  Search_people_here: 'Suche hier Leute',
  Search_team_here: 'Suchteam hier',
  people: 'MENSCHEN',
  teams: 'TEAMS',
  empty: '(leer)',
  can_not_be_assigned: 'Dieser Benutzer hat kein Team und kann nicht zugewiesen werden',
  select_a_team: 'Bitte wählen Sie ein Team für',
  confirm_team_change: 'Teamwechsel bestätigen',
  change_the_team_: 'Sind Sie sicher, dass Sie das Team ändern möchten?',
  can_not_see_it_anymore: 'Sie werden es in der aktuellen nicht sehen können',
  filtered_view: 'gefilterte Ansicht.',
  PHONE: 'Telefon',
  FAX: 'Fax',
  LETTER: 'Brief',
  F2F_SITE_VISIT: 'F2F (Sitzung)',
  F2F_CONGRESS: 'F2F (Kongress)',
  F2F_ADBOARD: 'F2F (AdBoard)',
  F2F_OTHER: 'F2F (Andere)',
  WEBINAR_SITE_VISIT: 'Virtuell (Sitzung)',
  WEBINAR_CONGRESS: 'Virtuell (Kongress)',
  WEBINAR_ADBOARD: 'Virtuell (AdBoard)',
  WEBINAR_OTHER: 'Virtuell (Andere)',
  CHAT: 'Plaudern',
  COURIER: 'Brief',
  REP_DELIVERY: 'Rep. Lieferung',
  SYSTEM: 'System',
  WEB_FORM: 'Web-Formular',
  Signature: 'Signatur',
  Disclaimer: 'Haftungsausschluss',
  STATE: 'Zustand',
  ADDRESS_1: 'Adresse 1',
  ADDRESS_2: 'Adresse 2',
  ZIP_CODE: 'Postleitzahl',
  HIGH: 'Hoch',
  ADVANCED: 'Fortgeschrittene',
  CLICK_BELOW_TO_POPULATE_ANSWER:
    'Klicken Sie auf den Text und die Felder unten, um die Antwort auszufüllen und zu bearbeiten',
  DOCUMENT_VIEW: 'Dokumentansicht',
  FORM_VIEW: 'Formularansicht',
  YOU_DONT_HAVE_RIGHTS_TO_ANSWER: 'Sie haben keine Rechte, die Antwort zu senden.',
  ANSWER_TEXT: 'Antworttext',
  CONTACT_INFORMATION: 'Persönliche Informationen',
  COMPANY: 'Unternehmen',
  SRD_NEEDED_TOOLTIP: 'Die Antwort auf diese Anfrage erfordert ein neues Standard-Antwortdokument',
  ENABLE_ALL: 'Alles aktivieren',
  DISABLE_ALL: 'Alle deaktivieren',
  BEFORE: 'Vor',
  AFTER: 'Nach dem',
  EDIT: 'Bearbeiten',
  MY_TEMPLATES: 'Meine Vorlagen',
  TEAM_TEMPLATES: 'Teamvorlagen',
  TEAM_TEMPLATES_HINT: 'Vom Medical Information Manager erstellte Vorlage für das gesamte Team',
  SOURCE: 'Quelle',
  TEMPLATE_NO_MATCH_INTERACTION:
    'Diese Vorlage stimmt nicht mit den Interaktionsdetails überein und ist nicht anwendbar',
  EMAIL_SUBJECT: 'E-Mail Betreff',
  INQUIRY_SUBMIT_HINT:
    'Bitte füllen Sie alle erforderlichen Felder aus, um die Anfrage zu beantworten',
  SCHEDULED_PUBLISH_TO_EXP_DATE: 'Geplante Veröffentlichung bis zum Ablaufdatum',
  COPY_ATTACHMENT_TO_ANSWER: 'Kopieren Sie den Anhang zur Antwort',
  COPY_TEXT_TO_ANSWER: 'Kopieren Sie den zu antwortenden Text',
  SELECT_INQUIRY: 'Wählen Sie Anfrage',
  EDIT_INQ: 'Frage bearbeiten',
  SPLIT_ANOTHER: 'Einen anderen teilen',
  DELETE_INQ: 'Anfrage löschen',
  SELECT_TEXT_FOR_SPLIT: 'Fügen Sie in derselben Interaktion eine neue Frage hinzu',
  SELECT_TEXT_FOR_SPLIT_NEW_INTERACTION: 'Separate Interaktion / Fall generieren',
  SPLIT_ORIGINAL_TEXT: 'Originaltext (Text zum Teilen auswählen)',
  SPLIT_TEXT_TO_REMAIN_: 'Text, der bei der ersten Anfrage verbleibt',
  SPLIT_TEXT_TO_SPLIT_: 'Zu teilender Text',
  SPLIT_SELECTED_SECTIONS: 'Ausgewählte Abschnitte zum Teilen',
  SPLIT_NO_MODIFICATIONS_HINT:
    'Der Text wird automatisch ausgefüllt, wenn Sie Abschnitte aus dem Originaltext auswählen',
  SPLIT_MODIFICATIONS_HINT:
    'Der Text wurde manuell geändert und eine weitere Auswahl aus dem Originaltext wird nicht angewendet.\n Klicken Sie hier, um die Änderungen zu verwerfen.',
  TEMPLATE_NO_MODIFICATIONS_HINT:
    'Der Text wird automatisch mit den Änderungen an den Anfragedetails aktualisiert.',
  SELECT_DIFFERENT_TEMPLATE: 'Wählen Sie eine andere Vorlage',
  APPROVE: 'Genehmigen',
  LAST_EDIT_ON: 'Letzte Bearbeitung ein',
  LAST_EDIT_BY: 'Letzte Bearbeitung von',
  INTERNAL_COMM: 'Interne Kommunikation',
  INACTIVE: 'Inaktiv',
  DEACTIVATE: 'Deaktivieren',
  ARCHIVED: 'Archiviert',
  REPUBLISH: 'Neu veröffentlichen',
  INBOX_NO_FILTERS_HINT:
    'Es sind keine Filter ausgewählt.\n Wenn Sie die gesuchte Anfrage nicht finden können, überprüfen Sie, ob Sie sich im richtigen Posteingangsbereich befinden.',
  INTERACTION_TEMPLATE_HINT:
    'Unterschriften, Grüße und Haftungsausschlüsse sind Teil der schriftlichen Kommunikation wie E-Mail, Brief und Fax.\n Um sie zu verwenden, stellen Sie sicher, dass der Ausgangskanal auf einen von ihnen eingestellt ist.',
  CONFIRM_AUTH_REQUEST: 'Bestätigungsanforderung bestätigen',
  VIEW_AUTH_REQUEST: 'Auth-Anfrage anzeigen',
  QUESTION_TITLE: 'Fragetitel: ',
  NO_TITLE_INQUIRY: '-keine-Titel-Anfrage-',
  EXISTING_CATEGORIES: 'Bestehende Kategorien',
  EXISTING_TOPICS: 'Bestehende Themen',
  DO_NOT_ASSIGN_PRODUCT: 'Produkt nicht zuordnen',
  QUESTION_TEXT: 'Fragetext',
  DATE: 'Datum',
  REJECTED: 'Abgelehnt',
  FILTER_AUTH_REQUESTS_BY: 'Filtern Sie Authentifizierungsanforderungen nach',
  ALL_REQUESTS_ALL_TEAMS: 'Alle Anfragen für alle Teams',
  ALL_TEAMS: 'Alle Teams',
  YOU_DONT_HAVE_RIGHTS_TO_EDIT_REQUEST:
    'Sie haben keine Rechte zum Bearbeiten von Kategorie- / Themenanforderungen.',
  SEND_FOLLOW_UP: 'Follow-up senden',
  RECEIPT_NOTIFICATION_CUSTOMER: 'Empfangsbenachrichtigung an den Kunden',
  GDPR_DISCLAIMER: 'DSGVO-Haftungsausschluss',
  ACTIONS: 'Aktionen',
  SUBMISSION_CONDITIONS: 'Einreichbedingungen',
  TYPE_OF_NOTIFICATION: 'Art der Benachrichtigung',
  DIRECT_SUBMISSION: 'Direkte Einreichung',
  DIRECT_SUBMISSION_TOOLTIP:
    'Senden Sie bei Anfragen direkt vom Kunden per E-Mail eine Benachrichtigung, um den Kunden über den Umgang mit seinen personenbezogenen Daten zu informieren.',
  COMPANY_EMPLOYEE_SUBMISSION: 'Eingereicht über einen Mitarbeiter des Unternehmens',
  ENABLE_NOTIFICATION: 'Benachrichtigungen aktivieren',
  NOTIFICATION_SENDER: 'Benachrichtigung an Absender',
  SALES_REP_DELIVERED_RESPONSE: 'Vertriebsmitarbeiter für gelieferte Antwort',
  NOTIFICATION_DELIVERED_RESPONSE: 'Benachrichtigung für zugestellte Antwort',
  NOTIFICATION_DELIVERED_RESPONSE_TOOLTIP:
    'Bei Anfragen, die über einen Unternehmensvertreter (Vertriebsmitarbeiter, MSL oder andere) eingereicht werden, den Absender benachrichtigen, wenn der Kunde eine Antwort auf seine Anfrage erhalten hat',
  FULL_ANSWER: 'Vollständige Antwort',
  NOTIFICATION: 'Benachrichtigung',
  NAME: 'Name',
  COLUMN_INIT_QUESTION_TEXT: 'Ursprünglicher Text der Frage',
  NOTIFICATION_ENABLED: 'Benachrichtigung aktiviert',
  DEFAULT_TEMPLATE: 'Standardvorlage',
  SET_AS_DEFAULT: 'Als Standard einstellen',
  DEFAULT_TEMPLATE_TOOLTIP:
    'Umschalten ist deaktiviert, da mindestens eine Standardvorlage vorhanden sein muss!',
  DEFAULT_TEMPLATE_TOOLTIP_DELETION_NOT_POSSIBLE: 'Standardvorlage kann nicht gelöscht werden!',
  BATCH_UPDATE_ONLY: 'Über das oberste Kontrollkästchen ist nur ein Batch-Update möglich.',
  ACCESS_DENIED: 'Zugriff verweigert!',
  YOU_DO_NOT_HAVE_PERMISSION_TO_ACCESS_THIS_PAGE:
    'Sie sind nicht berechtigt, auf diese Seite zuzugreifen.',
  USER_ROLES: 'Benutzerregeln',
  MANAGE_USER_ROLES: 'Benutzerrollen verwalten',
  ARCHIVE_ROLE: 'Archivrolle',
  ROLE_ARCHIVE_CONFIRMATION: 'Möchten Sie diese Rolle wirklich archivieren?',
  ROLES_FOR: 'Rollen für',
  AUDIT_TRAIL: 'Audit-Trail',
  ACTION_BY_USER: 'Aktion durch Benutzer',
  ACTION: 'Aktion',
  ORIGINAL_VALUE: 'Ursprünglicher Wert',
  CHANGE: 'Änderungsänderung',
  CONDITION: 'Zustand',
  IP: 'IP',
  GEOLOCATION: 'Geolokalisierung',
  NEW_VALUE: 'Neuer Wert',
  FILTER_BY_ACTION: 'Nach Aktion filtern',
  SELECT_DATE: 'Datum auswählen',
  TEAM_SAVE: 'Speichern',
  TEAM_DELETE: 'Löschen',
  TEAM_CREATE: 'Schaffen',
  TEAM_CLONE: 'Kopieren',
  TEAM_NAME: 'Name',
  TEAM_CODE: 'Code',
  TEAM_ACTION_DELETE_CONFIRM: 'Willst du wirklich löschen?',
  TEAM_ACTION_DELETE_CONFIRM_TITLE: 'Vorsichtig sein!',
  TEAM_ACTION_DELETE_CONFIRM_EXTENDED:
    'Sobald es gelöscht wurde, kann es nicht wiederhergestellt werden!',
  TEAM_FULL_ANSWER_ON_LABEL: 'Vollständige Antwort auf Etikett',
  TEAM_RESTRICTED_TEAM: 'Eingeschränkt',
  TEAM_SEGREGATED_DATA: 'Getrennte Daten',
  TEAM_PRIVATE_TEAM: 'Privatteam',
  TEAM_AUTO_ASSIGN: 'Automatisch zuweisen',
  TEAM_MAILBOX_IN: 'Inbound Mailbox',
  TEAM_MAILBOX_OUT: 'Outbound Mailbox',
  TEAM_DEFAULT_LANGUAGE: 'Standardsprache',
  TEAM_COUNTRIES: 'Zugewiesene Länder',
  TEAM_PRODUCT_GROUPS: 'Produktgruppen zuweisen',
  TEAM_PARENT: 'Elternteam',
  TEAM_OVERRIDE_CLUSTER_ROOT: 'Das Cluster -Root -Team überschreiben',
  TEAM_AFFILIATIONS: 'Verbundene Teams',
  TEAM_TIMEZONE: 'Zeitzone',
  UPDATE: 'Aktualisieren',
  AUTO_ASSIGN_BY: 'Automatisch zuweisen von',
  AUTO_ASSIGNMENTS: 'Automatische Aufgaben',
  NO_ONE: 'Niemand',
  NOT_APPLICABLE: 'Unzutreffend',
  OVERRIDE_NOTIFICATIONS: 'Benachrichtigungen überschreiben',
  OVERRIDE_NOTIFICATIONS_HINT:
    'Wenn Benutzervorlage mit Überschreibungsbenachrichtigungen auf den Benutzer festgelegt wird, deaktiviert sie bereits vorhandene Benachrichtigungseinstellungen und aktiviert nur die in der Benutzervorlage aufgeführten Benachrichtigungseinstellungen',
  CREATED_MANUALLY: 'Manuell erstellt',
  DOCUMENT_TITLE_FOR_WORD:
    'Der Titel für Word -Dokumente wird normalerweise aus dem Abschnitt "Titel formatiert" innerhalb des Dokuments selbst entnommen. Wenn das Dokument keinen Titel enthält, können Sie ihn hier angeben. Wenn Sie hier nicht den Titel angeben und das Word Dokument keinen Titel -Format -Abschnitt mit dem Titel enthält, wird die Dokument -ID für den Titel verwendet.',
  ORDER_BY: 'Sortieren nach',
  FORMAT: 'Format',
  SELECT_QUESTION: 'Wähle eine Anfrage aus',
  SELECT_QUESTION_FOR_NOTE: 'Wählen Sie eine Frage, für die Sie einen Hinweis hinzufügen möchten',
  ADD_NOTE_FOR_WHOLE_ENQUIRY: 'Hinweis für die ganze Anfrage hinzufügen',
  REQUESTED_DATE: 'Wunschtermin',
  UNASSIGNED: 'Nicht zugewiesen',
  BLANK: 'Leer',
  ANSWER_SAVED_WITHOUT_EMAIL: 'Antwort ohne E -Mail gespeichert',
  INQ_RELATED_TO: 'Anfragen im Zusammenhang mit',
  QUESTION_TEXT_BEFORE_SPLIT: 'Befragtext vor dem Aufteilen',
  SPLIT_MERGE: 'Teilen und Zusammenführen',
  CLARIFICATION_QUESTION: 'Klarstellungsfrage',
  ACTIVE_ON: 'Aktiv auf',
  DISMISS: 'Zurückweisen',
  GO_TO_ENQUIRY: 'Gehen Sie zur Untersuchung',
  NO_TEAMS: 'Ohne Team',
  NO_TEAMS_TIP:
    'Enthält Benutzer, die keinem Team zugeordnet sind. Sie können überprüfen, ob einige davon von Ihrem Team stammen und ihnen die entsprechende Rolle gewähren.',
  TASKS: 'Aufgaben',
  Therapeutic_Areas: 'Therapeutische Gebiete',
  Product_Groups: 'Produktgruppen',
  Licensing_Partner: 'Lizenzpartner',
  Product_Code: 'Produktcode',
  SELECT_EXISTING_ONE: 'Wählen Sie vorhanden',
  OR: 'oder',
  MANAGE_FORM: 'Form verwalten',
  EVENT_FORM_ADDRESS: 'Ereignisformularadresse',
  EVENT_FORM_PREVIEW_WARING: 'Stellen Sie sicher, dass Sie Änderungen gespeichert haben',
  SEARCH_SAVED_SEARCHES: 'Suche gespeicherte Suchanfragen',
  GO: 'gehen',
  ASSIGN: 'Zuordnen',
  CERTIFICATION: 'Zertifizierung',
  SORT: 'Sortieren',
  CREATE_NEW_MAILBOX: 'Neue Mailbox erstellen',
  EDIT_MAILBOX: 'Mailbox bearbeiten',
  ADD_MAILBOX: 'Postfach hinzufügen',
  UNSET_INCOMING_MAILBOX: 'Nicht eingehender Postfach',
  SET_INCOMING_MAILBOX: 'Setzen Sie eingehende Mailbox',
  UNSET_OUTGOING_MAILBOX: 'Nicht festgelegte Postfach',
  SET_OUTGOING_MAILBOX: 'Legen Sie ausgehender Briefkasten ein',
  ALL_TEAMS_WITH_CURRENT_MAILBOX: 'Alle Teams mit aktuellem Mailbox',
  MAILBOX_HISTORY: 'Mailbox -Verlauf',
  ALL_TEAMS_WITH_MAILBOX: 'Alle Teams mit Mailbox',
  MAILBOX: 'Briefkasten',
  EMAIL_POLLING: 'E -Mail -Umfragen',
  ADVANCED_SEARCH: 'Erweiterte Suche',
  SELECT_FIELDS: 'Felder auswählen',
  SEARCH_TERM: 'Suchbegriff',
  COLUMN_INTERACTION_ID: 'Interaktions -ID',
  COLUMN_QUESTION_ID: 'Frage id',
  COLUMN_CREATED_ON: 'Erstellt am',
  COLUMN_LAST_EDITED_DATE: 'Zuletzt bearbeitet auf',
  COLUMN_ASSIGNED_TO: 'Zuweisen',
  COLUMN_DUE_DATE: 'Geburtstermin',
  COLUMN_FOLLOW_SENT_DATE: 'Nachfolgerdatum',
  COLUMN_FOLLOW_RECEIVED_DATE: 'Nachfolgerdatum',
  COLUMN_ANSWER_DATE: 'Antwortdatum',
  COLUMN_CLOSED_ON_DATE: 'Am Datum geschlossen',
  COLUMN_REOPENED_DATE: 'Wiedereröffnetes Datum',
  COLUMN_INSERTED_BY: 'Eingefügt von',
  COLUMN_LAST_EDITED_BY: 'Zuletzt bearbeitet von',
  COLUMN_ANSWERED_BY: 'Beantwortet von',
  COLUMN_APPROVED_BY: 'Genehmigt durch',
  COLUMN_INT_COMM_USER: 'Interner Kommunikation Benutzer',
  COLUMN_INT_COMM_DATE: 'Internes Kommunikationsdatum',
  COLUMN_MY_TEAM: 'Mein Team',
  COLUMN_PQC_RELATED: 'PQC verwandt',
  COLUMN_INCOMING_CHANNEL: 'Eingehender Kanal',
  COLUMN_OUTGOING_CHANNEL: 'Ausgehender Kanal',
  COLUMN_OFF_LABEL: 'Off-Label',
  COLUMN_HCP_SPEC: 'Medizinischer Beruf + Spezialisierung',
  COLUMN_NON_HCP_SPEC: 'Nichtmedizinischer Beruf + Beruf',
  COLUMN_KEE: 'Kee',
  COLUMN_HOSPITAL_BASED: 'Krankenhausbasis',
  COLUMN_CITY: 'Stadt',
  COLUMN_INSTITUTION: 'Institution',
  COLUMN_TERRITORY: 'Gebiet',
  CASE_ID: 'Fall-ID',
  OPTION_ADD: 'HINZUFÜGEN',
  OPTION_AND: 'UND',
  OPTION_OR: 'ODER',
  OPTION_NOT: 'NICHT',
  OPTION_YES: 'Ja',
  OPTION_NO: 'Nein',
  QUERY: 'Anfrage',
  EXPORT_LABEL: 'Exportspalten definieren',
  SAVE_SEARCH: 'Sichere Suche',
  SHARE_SEARCH: 'Search teilen',
  SEARCH_BTN: 'Suche',
  BACK_ACTION: 'Der Rücken',
  SAVE_CHANGES: 'Änderungen speichern',
  SAVED_SEARCH: 'Gespeicherte Suche',
  NEW: 'Neu',
  SAVE_AS: 'Speichern Sie als Name',
  ENTER_RECIPIENTS: 'Empfänger eingeben',
  CLEAR_SAVED_SEARCH: 'Klare gespeicherte Suchanfragen',
  NO_SAVED_SEARCH: 'Keine gespeicherte Suche gefunden',
  INT_COMM_TEXT: 'Interner Kommunikationstext',
  FOLLOW_UP_TEXT: 'Follow-up-Text',
  CONFIRM_DELETION: 'Löschung bestätigen',
  ARE_SURE: 'Sind Sie sicher, dass Sie die gespeicherte Suche löschen möchten?',
  PERMANENTLY_DELETED: 'Die Suche wird dauerhaft <b> gelöscht </b>.',
  NOTHING_SAVE: 'Nichts zu speichern.',
  NOTHING_SEARCH: 'Nichts zu suchen.',
  NOTHING_EXPORT: 'Die Suche hat keinen Datensatz für den Exportieren.',
  SHARE_SUCCESS: 'Die Suche wurde erfolgreich geteilt',
  SHARE_FAILURE: 'Die Suche konnte nicht geteilt werden',
  DELETED_SUCCESS: 'Erfolgreich gelöschte gespeicherte Suche gelöscht',
  ALL_DELETED_SUCCESS: 'Erfolgreich gelöscht alle gespeicherten Suchanfragen',
  VIEW_SAVED_SEARCH: 'Saved Search anzeigen',
  EDIT_SAVED_SEARCH: 'Saved Search bearbeiten',
  EXPORT_SAVED_SEARCH: 'Exportierte gespeicherte Suche',
  EXPORT_SEARCH: 'Exportsuche',
  DELETE_SAVED_SEARCH: 'Saved Search löschen',
  SELECT_EXPORT_COLUMNS: 'Wählen Sie Spalten zum Exportieren aus',
  SAVED_SEARCH_ALREADY_EXIST: 'Suchen Sie mit diesem Namen bereits.',
  CHARACTER_LIMIT: 'Min 3 Zeichen. Max 35 Zeichen.',
  CHARACTER_LEFT: 'Charaktere sind übrig',
  ACTIVATE: 'aktivieren Sie',
  INACTIVE_USER:
    'Dieser Benutzer ist nicht mehr aktiv. Bitte kontaktieren Sie Ihren Systemadministrator.',
  FOLLOW_UP: 'Nachverfolgen',
  USER_NOT_FOUND: 'Benutzer nicht gefunden',
  DEPARTMENTS: 'Abteilungen',
  EMAIL: 'EMAIL',
  ADD_NEW_DEPARTMENT: 'Neue Abteilung hinzufügen',
  DEPARTMENT_COUNTRY: 'LAND',
  DEPARTMENT_COMPANY: 'GESELLSCHAFT',
  DEPARTMENT_TEAM: 'MANNSCHAFT',
  DEPARTMENT_TA: 'Therapeutischer Bereich',
  DEPARTMENT_PRODUCT: 'PRODUKT',
  DEPARTMENT_ACTIONS: 'AKTIONEN',
  DEPARTMENT_CONFIRMATION_1: 'Löschung bestätigen?',
  DEPARTMENT_CONFIRMATION_2: 'Diese Aktion ist irreversibel.',
  DEPARTMENT_NAME: 'Abteilungsname',
  TYPE_DEPARTMENT: 'Typ',
  COUNTRY_PLEASE_SELECT_TEAM_FIRST: 'Land - Bitte wählen Sie das Team zuerst aus',
  DEP_NAME_CHARACTER_LIMIT: 'Max. 200 Zeichen.',
  AUTH_REQUESTS: {
    TITLE: {
      UserRequest: 'Benutzeranfrage',
      CategoryTopicRequest: 'Kategorie/Themenanforderung',
      ProductRequest: 'Produktanfrage',
      UserUpdateRequest: 'Benutzeraktualisierungsanforderung',
    },
    TYPE: {
      NewUser: 'Neuer Benutzer',
      NewCategoryTopic: 'Neue Kategorie/Thema',
      NewUserUpdate: 'Benutzeraktualisierung',
    },
  },
  ADMINISTRATION_SECTION: {
    SPECIALIZATION: {
      DEFINE_COUNTRY_SPECIFIC_SPECIALIZATIONS:
        'Definieren Sie die länderspezifische Spezialisierungsliste',
      IS_COUNTRY_SPECIFIC: 'Ist länderspezifisch',
      TYPE: 'Anfragetyp',
      SPECIALIZATION_EXISTS:
        'Die Konfiguration für „Anfragertyp/Spezialisierung“ ist bereits vorhanden.',
    },
    AUTHORIZATION_REQUESTS: { GENDER: 'Geschlecht', COUNTRY: 'Land', TITLE: 'Titel' },
  },
  hcp: 'Medizinischer Beruf',
  emp: 'Angestellter',
  cons: 'Nichtmedizinischer Beruf',
  OOO_DEPUTY: 'Abwesenheitsvertretung',
  NO_DEPUTY: 'Kein Vertreter',
  UNSPECIFIED_END_DATE: 'Nicht spezifiziertes Enddatum',
  DELEGATORS: 'Delegatoren',
  NO_DELEGATORS: 'Keine Delegatoren',
  PREVIEW_DELEGATORS: 'Vorschau -Delegatoren',
  REMOVE_DEPUTY: 'Stellvertreter entfernen',
  USERS_WHO_SET_YOU_AS_DEPUTY: 'Benutzer, die Sie als Stellvertreter einstellen',
  CHANGED_USER: 'Benutzer geändert',
  DEFAULT: 'Standard',
  SPECIALIZATIONS: 'Spezialisierungen',
  ENQUIRERS_LIST: 'Anfrager-Liste',
  EDIT_THE_RECORD: 'Bearbeiten Sie den Datensatz',
  DEACTIVATE_THE_RECORD: 'Deaktivieren Sie den Datensatz',
  USER_PROFILE: {
    OUT_OF_OFFICE: {
      OUT_OF_OFFICE: 'Abwesenheitsvertretung',
      HINT: 'Es stehen nur aktive Benutzer mit der Anfrageverarbeitungsrolle, die nicht im Abwesenheit sind, für die Auswahl zur Verfügung.',
      MODAL_TITLE: 'Bestätigen Sie die Büroeinstellungen',
      MODAL_MSG_1:
        'Sie haben mit sofortiger Wirkung die Option Abwesenheit festgelegt, alle Ihre Anfragen werden dem Vertreter sofort zugewiesen.',
      MODAL_MSG_2:
        'Sie haben die Option Abwesenheit herausgebracht, alle Ihre Anfragen werden dem Vertreter während der Abwesenheit zugewiesen.',
    },
  },
  GENDER_MALE: 'Herr',
  GENDER_FEMALE: 'Frau',
  TITLE_DR: 'DR.',
  TITLE_PHD: 'PhD',
  TITLE_PROF: 'Prof.',
  TITLE_PROF_DR: 'Professor Doktor.',
  HOME: 'Privat',
  HOSPITAL: 'Krankenhaus',
  OFFICE: 'Büro',
  WORK: 'Arbeit',
  INSERT_IMAGE: 'Bild einfügen',
  USER_ADMIN: 'Benutzeradmin',
  MAILBOXES: 'Postfächer',
  AUTHORIZATION_REQUESTS: 'Autorisierungsanfragen',
  METADATA: 'Metadaten',
  NOTIFICATIONS_PAGE: {
    TITLES: {
      ASSIGNMENT: 'Abtretung',
      FORWARD: 'Nach vorne',
      FOLLOW_UP: 'Nachverfolgen',
      DUE_DATE_SETTINGS: 'Fälligkeitsdatumeinstellungen',
      CLOSE: 'Nah dran',
      DELIVERY_ERROR: 'Lieferfehler',
      SCHEDULER: 'Planerin Planer',
      USER_REPORTS: 'Benutzerberichte',
      DOCUMENT_APPROVAL: 'Genehmigung der Dokument',
      AUTHORIZATION_REQUEST: 'Autorisierungsanfragen',
      NEW_INQUIRY: 'Neue Anfrage',
      INQUIRY_APPROVAL: 'Anfragegenehmigung',
    },
    TYPES: {
      AUTHORIZATION_REQUEST_1: 'Eine Rollenanfrage wurde genehmigt/abgelehnt',
      AUTHORIZATION_REQUEST_3: 'Eine Rolle wartet auf meine Zustimmung',
      DOCUMENT_APPROVAL_1: 'Ein Dokument wartet auf Ihre Genehmigung',
      DOCUMENT_APPROVAL_2: 'Ein Dokument wurde genehmigt/abgelehnt',
      DOCUMENT_APPROVAL_3: 'Ein Dokument wurde archiviert',
      DOCUMENT_APPROVAL_4: 'Ein Dokument wurde mit Änderungen genehmigt',
      DOCUMENT_APPROVAL_5: 'Ein Dokument wird in archiviert',
      DOCUMENT_APPROVAL_6: 'Liste der genehmigten Dokumente',
      DUE_DATE_2: 'Wiederholen Sie dies nach der Vergangenheit',
      ERROR_1: 'Versagen zur Lieferbenachrichtigung (z. B. falsche E -Mail)',
      FOLLOW_UP_1: 'Eine Follow -up oder eine Erinnerung wurde an den Kunden gesendet',
      FOLLOW_UP_4: 'Ein Follow -up wurde empfangen',
      IMPORT_DATA_1: 'Während des Imports wurde ein neues Objekt erstellt',
      IMPORT_DATA_4: 'Während des Imports wurde ein neuer Benutzer erstellt',
      IMPORT_DATA_5:
        'Ein Benutzer stimmte mit einem bereits vorhandenen, kam aber nicht aus einem früheren Import',
      IMPORT_DATA_6: 'Während des Imports von Daten aus einer externen Quelle trat ein Fehler auf',
      INQUIRY_APPROVAL_4:
        'Ihre vorgeschlagene Antwort wurde mit Änderungen genehmigt / genehmigt / abgelehnt',
      METADATA_MANAGEMENT_1: 'Es wurde eine neue Metadaten erstellt',
      NEW_DOCUMENT_1: 'Neu zugelassenes Dokument ist in meinem Land erhältlich',
      NEW_DOCUMENT_2: 'Neues Dokument steht zur lokalen Genehmigung zur Verfügung',
      NEW_DOCUMENT_3:
        'Neues lokales Dokument ist für die europäische / globale Genehmigung verfügbar',
      NEW_DOCUMENT_4: 'Neues globales Dokument ist verfügbar',
      NEW_DOCUMENT_5: 'Neues europäisches Dokument ist verfügbar',
      NEW_DOCUMENT_6: 'Neues europäisches Dokument steht zur globalen Genehmigung zur Verfügung',
      SCHEDULER_TASK_1: 'Während der Ausführung einer Aufgabe trat ein Fehler auf',
      USER_REPORTS_1: 'Liste der aktiven Benutzer',
      TEXT_GUIDES: {
        AUTHORIZATION_REQUEST_3:
          'Erhalten Sie Benachrichtigungen, die Sie informieren, wenn eine Benutzerrollenanforderung genehmigt/abgelehnt werden muss',
        AUTHORIZATION_REQUEST_2:
          'Erhalten Sie Benachrichtigungen, wenn ein neuer Benutzer erstellt wurde oder wenn eine Rolle von einem Benutzer in Ihrem Team angefordert wurde',
        AUTHORIZATION_REQUEST_4:
          'Erhalten Sie Benachrichtigungen, wenn ein neues Thema und/oder eine Kategorie vorgeschlagen wurden',
        FOLLOW_UP_4:
          'Erhalten Sie Benachrichtigungen für Anfragen, die mir zugewiesen sind und für die eine Folgeantwort vom Anfragenden eingegangen ist',
        NEW_INQUIRY_1:
          'Erhalten Sie Benachrichtigungen über neue Anfragen, die in Ihrem Land eingehen, von einem Anfragenden/Benutzer eingegeben oder von einem anderen Team weitergeleitet wurden',
        ANSWER_2:
          'Erhalten Sie Benachrichtigungen für beantwortete Anfragen, bei denen ich als Mitarbeiter mitgearbeitet habe, aber nicht direkt der Anfrage zugeordnet bin',
        DUE_DATE_1:
          'Wenn Sie diese Option auswählen, werden alle der folgenden Benachrichtigungen aktiviert. Um die Anzahl der Benachrichtigungen zu verringern, deaktivieren Sie die nicht benötigten Benachrichtigungen.',
      },
      FREQUENCIES: {
        INSTANTLY: 'Sofort',
        DAILY: 'Täglich',
        WEEKLY: 'Wöchentlich',
        QUARTERLY: 'Vierteljährlich',
        MONTHLY: 'Monatlich',
        SECOND_DAY: 'Jeden zweiten Tag',
        THIRD_DAY: 'Jeden dritten Tag',
        ONE_WEEK: 'Eine Woche',
        ONE_MONTH: 'Ein Monat',
        TWO_MONTHS: 'Zwei Monate',
        THREE_MONTHS: 'Drei Monate',
        SIX_MONTHS: 'Sechs Monate',
        NINE_MONTHS: 'Neun Monate',
        EVERY_TWO_WEEKS: 'Alle zwei Wochen',
        EVERY_THREE_WEEKS: 'Alle drei Wochen',
        EVERY_FOUR_WEEKS: 'Alle vier Wochen',
      },
      OPTIONS: {
        ALMOST_DUE: 'Fast fällig',
        DUE_NOW: 'Jetzt fällig',
        DUE_SOON: 'Bald fällig',
        EARLY_ON: 'Frühzeitig',
        TEXT_GUIDES: {
          DUE_NOW: 'Erhalten Sie Benachrichtigungen, wenn die Anfrage fällig ist',
          EARLY_ON:
            'Erhalten Sie frühzeitig Benachrichtigungen für fällige Anfragen: Normal 4 Tage – Hoch 2 Tage – Kritisch 24 Stunden vor dem Fälligkeitsdatum',
          DUE_SOON:
            'Erhalten Sie Benachrichtigungen für Anfragen, die bald fällig sind: Normal 48 Stunden - Hoch 12 Stunden - Kritisch 6 Stunden vor dem Fälligkeitsdatum',
          ALMOST_DUE:
            'Erhalten Sie Benachrichtigungen für Anfragen, die bald fällig sind: Normal 24 Stunden – Hoch 6 Stunden – Kritisch 3 Stunden vor dem Fälligkeitsdatum',
        },
      },
      AUTHORIZATION_REQUEST_4: 'Eine Kategorie/ ein Thema wurde in meinem Team angefordert',
      AUTHORIZATION_REQUEST_2: 'Benutzeranfragen',
      IMPORT_DATA_2: 'Beim Import wurde ein neuer Anfragender angelegt',
      IMPORT_DATA_3:
        'Eine Anfrage stimmte mit einer bereits vorhandenen überein, stammte aber nicht aus einem früheren Import',
      ANSWER_2: 'Eine Anfrage, an der ich beteiligt war, wurde beantwortet',
      ASSIGNMENT_1: 'Mir wurde eine Anfrage zugewiesen',
      ASSIGNMENT_5: 'Eine Untersuchung wurde meinem Stellvertreter zugewiesen',
      CLOSE_1: 'Eine Anfrage wurde geschlossen',
      DUE_DATE_1:
        'Eine mir zugewiesene Anfrage steht kurz vor dem Fälligkeitsdatum. Informieren Sie mich',
      FOLLOW_UP_7: 'Eine Anfrage ging bei der Nachverfolgung verloren',
      FORWARD_1: 'Eine Anfrage wurde weitergeleitet',
      INQUIRY_APPROVAL_1: 'Eine Anfrage wartet auf Ihre Genehmigung',
      INTERNAL_1: 'Zu meiner zugewiesenen Anfrage wurde eine Notiz hinzugefügt',
      NEW_INQUIRY_1: 'In meinem Land ist eine neue Anfrage eingegangen',
      SPAM_1: 'Neue Anfrage im Spam erhalten',
      ANSWER_1: 'Erhalten Sie eine Kopie der Antwort in von mir beantworteten Interaktionen',
      ANSWER_6: 'Erhalten Sie eine Kopie der Antwort in von mir genehmigten Interaktionen',
    },
  },
  NEW_TEAM: 'Neues Team',
  SmPc: 'Fachinformation',
  USERS: 'Benutzer',
  PAGINATOR_NEXT_PAGE_LABEL: 'Nächste Seite',
  PAGINATOR_PREV_PAGE_LABEL: 'Vorherige Seite',
  PAGINATOR_FIRST_PAGE_LABEL: 'Erste Seite',
  PAGINATOR_LAST_PAGE_LABEL: 'Letzte Seite',
  PAGINATOR_ITEMS_PER_PAGE_LABEL: 'Objekte pro Seite',
  Date: 'Datum',
  Action: 'Aktion',
  'Action by user': 'Aktion durch Benutzer',
  'Changed user': 'Benutzer geändert',
  'Original value': 'Originalwert',
  'New value': 'Neuer Wert',
  REGION: 'Region',
  TEAM_REGION_LABEL: 'Region - Bitte wählen Sie Firma zuerst aus',
  TEAM_THERAPEUTIC_AREA_LABEL: 'Therapeutische Bereiche - Bitte wählen Sie Firma zuerst aus',
  TEAM_PRODUCT_LABEL: 'Produkte - Bitte wählen Sie Therapeutic Area zuerst aus',
  TEAM_COUNTRIES_LABEL: 'Länder - Bitte wählen Sie Region zuerst aus',
  DEFINE_PRODUCTS_FOR_EACH_COUNTRY: 'Definieren Sie Produkte für jedes Land',
  PLEASE_SELECT_AT_LEAST_ONE_PRODUCT_FOR: 'Bitte wählen Sie mindestens ein Produkt für aus',
  VIEW_CHANGES: 'Änderungen anzeigen',
  Sales_Representative: 'Außendienstmitarbeiter',
  Other_Commercial: 'Andere Werbespots',
  REGIONS: 'Regionen',
  MAIN_CLIENT: 'Hauptkunde',
  LICENSE_PARTNER: 'Lizenzpartner',
  TEAM_SET_UP: 'Team eingerichtet',
  LIST_TEAMS: 'Listen Sie Teams auf',
  COMPANIES: 'Firmen',
  DOMAINS: 'Domänen',
  TOKEN_REFRESH_NOTICE:
    'Sie haben eine neue Bestätigungs -E -Mail für Ihre ENQMed -Kontoregistrierung angefordert.',
  TOKEN_REFRESH_SUCCESS: 'Ein neuer Bestätigungslink wurde an Ihre E -Mail gesendet.',
  TOKEN_REFRESH_ERROR:
    'Es ist ein Fehler aufgetreten. Bitte wenden Sie sich an Support, wenn Sie weitere Unterstützung benötigen.',
  PLEASE_ENTER_MAX: 'Bitte geben Sie maximal ein',
  COMPANY_ACTION_EDIT_CONFIRM_TITLE:
    'Bitte überprüfen Sie die folgenden Änderungen, falls vorhanden, und bestätigen Sie, ob das Unternehmen geändert werden sollte.',
  COMPANY_ACTION_EDIT_WARNING_MESSAGE:
    'Bitte gehen Sie mit Vorsicht vor, da die Änderungen die Teams beeinflussen',
  THERE_ARE_NO_CHANGES: 'Es gibt keine Änderungen.',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Bitte überprüfen Sie die vorhandenen Unternehmen in der Tabelle hinter dem Dialog, da wir bereits die Gesellschaft mit den gleichen Werten haben.',
  SIMPLE_TEXT:
    'Bitte geben Sie nur Buchstaben (A-Z, A-Z), Zahlen (0-9) und spezielle Symbole wie (Dash, Periode, Raum und Dickdarm) ein.',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_REGIONS:
    'Bitte wählen Sie mindestens ein Land für Region aus',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_THERAPEUTIC_AREAS:
    'Bitte wählen Sie mindestens ein Produkt für den therapeutischen Bereich aus',
  COMPANY_DIALOG_VALIDATION_MESSAGE_FOR_PRODUCTS:
    'Sie haben Produkte ausgewählt, das zu mehr als einem therapeutischen Bereich gehört. Bitte wählen Sie alle benötigten therapeutischen Bereiche aus.',
  HTML_CHANGES: 'HTML ändert sich',
  SIDE_BY_SIDE_VIEW: 'Seite an Seitenansicht',
  DOMAIN_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Bitte überprüfen Sie die vorhandenen Domänen in der Tabelle hinter dem Dialogfeld, da wir bereits Domain mit denselben Werten haben.',
  OUT_CHANNEL_ERROR_MSG: 'Ausgangspunkte Postanschrift von Channel Letter Enquirer',
  OUT_CHANNEL_ERROR_MSG_LONG:
    'Ausgehender Kanal als Brief ausgewählt. Bitte geben Sie die Postadresse von Enquirer innerhalb des Enquirer -Bereichs ein',
  LABEL_FOR: 'Etikett für',
  male: 'Männlich',
  female: 'Weiblich',
  ARCHIVE_ROLE_DIALOG_WARNING_MESSAGE:
    'Sie haben kein Recht, diese Rolle zu archivieren. Nur <b> Mims </b> für das Team kann Rollen für ihre Teammitglieder archivieren.',
  SEND_EMAIL_UPON_APPROVAL_OR_REJECTION:
    'Senden Sie eine E -Mail an den Benutzer auf Anfrage Genehmigung/Ablehnung',
  APPROVE_REQUEST_QUESTION: 'Möchten Sie die Anfrage genehmigen?',
  NEW_USER_REQUEST: 'Neue Benutzeranforderung',
  OPEN_REQUESTS: 'Anfragen offen',
  APPROVED_REQUESTS: 'Genehmigte Anfragen',
  REJECTED_REQUESTS: 'Ablehnte Anfragen',
  USER_REQUESTS: 'Benutzeranfragen',
  REQUEST_FOR: 'Anfrage für',
  CATEGORY_TOPIC_REQUESTS: 'Kategorie/Themenanfragen',
  WITH_THE_FOLLOWING_PAIRS: 'mit den folgenden Paaren:',
  MY_TEAM_OPEN_REQUESTS: 'Anfragen offen',
  MY_TEAM_APPROVED_REQUESTS: 'Genehmigte Anfragen',
  MY_TEAM_REJECTED_REQUESTS: 'Ablehnte Anfragen',
  DOCUMENT_APPROVAL_WITHIN_ENQMED: 'Dokumentgenehmigung innerhalb von EnqMed',
  DOCUMENTS_APPROVAL: 'Genehmigung dokumentiert',
  DOCUMENTS_APPROVAL_TITLE:
    'Einstellungen für Dokumente Genehmigungsprozess für das ausgewählte Team',
  OUT_CHANNEL_FAX_ERROR_MSG_LONG:
    'Ausgehender Kanal als Fax ausgewählt. Bitte geben Sie die Faxnummer von Enquirer im Bereich Enquirer ein',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG_LONG:
    'Ausgehender Kanal als Telefon ausgewählt. Bitte geben Sie die Telefonnummer von Enquirer im Bereich Enquirer ein',
  OUT_CHANNEL_FAX_ERROR_MSG: 'Der Wert des ausgehenden Channel Fax Enquirer',
  OUT_CHANNEL_TELEPHONE_ERROR_MSG: 'Der Wert des ausgehenden Channel Phone Enquirer',
  COPYRIGHT_PART_ONE: 'Urheberrechte',
  COPYRIGHT_PART_TWO: 'Madjenta Ltd. Alle Rechte vorbehalten.',
  REVIEWED_DATE: 'Überprüftes Datum',
  OUT_CHANNEL_EMAIL_ERROR_MSG_LONG:
    'Ausgehender Kanal als E -Mail ausgewählt. Bitte geben Sie die E -Mail von Enquirer im Bereich Enquirer ein',
  OUT_CHANNEL_EMAIL_ERROR_MSG: 'Ausschaufende Kanal -E -Mail -Enquirer -Wert',
  ENTER_USER_COMMENT_OPTIONAL: 'Geben Sie einen Kommentar an den Benutzer ein (optional)',
  SELECT_ANOTHER_CATEGORY: 'Wählen Sie eine andere Kategorie aus',
  SELECT_ANOTHER_PRODUCT: 'Wählen Sie ein anderes Produkt aus',
  SUGGESTED_TOPIC: 'Vorgeschlagenes Thema',
  EDIT_USER_REQUEST: 'Benutzeranforderung bearbeiten',
  NEW_TOPIC_REQUEST: 'Neues Thema vorgeschlagen',
  EDIT_TOPIC_REQUEST: 'Bearbeiten vorgeschlagenes Thema',
  NEW_CATEGORY_REQUEST: 'Neue Kategorie vorgeschlagen',
  EDIT_CATEGORY_REQUEST: 'Bearbeiten vorgeschlagene Kategorie',
  REVIEW_COMMENT: 'Überprüfung Kommentar',
  DOCUMENTS_APPROVAL_WORKFLOW: {
    PAGE_TITLE: 'Dokumentgenehmigungsarbeitsabläufe',
    CREATE_NEW: 'Erstellen Sie einen neuen Workflow',
    CUSTOMIZE: 'Anpassen der Workflow',
    NAME: 'Workflow -Name',
    ERROR_MSG_DUPLICATE_NAME: 'Workflow mit diesem Namen gibt es bereits.',
    SAVE: 'Workflow speichern',
    DELETE: 'Workflow löschen',
    STEP: 'Schritt',
    ALL_USERS_WITH_DOC_AUTHOR_ROLE: 'Alle Benutzer mit Dokumentautorrolle.',
    APPROVAL_DEADLINE: 'Zulassungsfrist',
    SELECT_APPROVAL_TYPE: 'Wählen Sie den Genehmigungstyp',
    SELECT_APPROVAL_TYPE_FIRST: 'Wählen Sie zuerst Genehmigungsart',
    SELECT_REVIEWERS: 'Wählen Sie Rezensenten aus',
    ACTION_ON_APPROVE_WITH_CHANGES: 'Aktion auf Genehmigung mit Änderungen',
    ACTION_ON_REJECT: 'Aktion auf Ablehnung',
    FILL_ALL_REQUIRED_FIELDS:
      'Bitte füllen Sie alle erforderlichen Felder aus, um einen neuen Schritt hinzuzufügen.',
    ALL_APPROVAL_TYPES_USED:
      'Sie können nicht mehr Genehmigungs -Sub -Schritte hinzufügen, da Sie alle für den aktuellen Schritt verfügbaren Typen verwendet haben.',
    CONFIRMATION_ROW_1:
      'Sie versuchen, einen Workflow für das Team für das Team zu löschen {{ NAME }}.',
    CONFIRMATION_ROW_2: 'Möchten Sie den Genehmigungsworkflow wirklich löschen?',
    CONFIRMATION_TITLE: 'Dokumentgenehmigung Workflow Löschung',
    TEAM_VIEW_SELECT_EXISTING:
      'Wählen Sie einen vorhandenen Workflow der Dokumentgenehmigung aus der folgenden Liste oder aus oder ',
    DOC_VIEW_SELECT_EXISTING: 'Wählen Sie einen vorhandenen Workflow für Dokumentgenehmigungen aus',
    ONLY_SINGLE_APPROVAL_STEP_CAN_BE_LISTED_AS_FINAL:
      'Nur einzelner Genehmigungsschritt kann als endgültig aufgeführt werden.',
    APPROVAL_TYPE_TOOLTIP:
      'Für dieses Team gibt es keine Benutzer mit der Genehmigungsrolle der Dokumente. Bitte fügen Sie zuerst die Genehmigungsrollen hinzu.',
    INFO_DIALOG_TITLE: 'Für dieses Team gibt es keinen Dokumentengenehmigungs -Workflow -Set.',
    INFO_DIALOG_MSG:
      'Bitte wenden Sie sich an Ihr lokales MIM, um den Workflow für das Team für das Team für das Team zu erstellen.',
    STEP_LIMIT_REACHED: 'Sie haben das Limit von {{ MAX_STEPS }} Genehmigungsschritten erreicht.',
  },
  DOCUMENTS_WITHOUT_APPROVAL: 'Dokumenttypen, die keine Genehmigung erfordern',
  PLEASE_SELECT_TYPE: 'Bitte wählen Sie Typ',
  ABORT_FILE_UPLOAD: 'Datei hochladen',
  USERS_REPORT: 'Benutzer melden',
  DOCUMENT_CREATE_CONFIRM_CLOSE_MSG:
    'Wenn Sie dieses Fenster schließen, verlieren Sie die bereits ausgefüllten Informationen.',
  DO_YOU_WANT_TO_PROCEED: 'Möchten Sie fortfahren?',
  USERS_REPORT_DESCRIPTION:
    'Liste der Benutzer aller zusätzlichen Teams, in denen MIM für Daiichi Sankyo Europe Oncology und Cardiovaskular Sankyo Europa spielt.',
  TEMPLATE_CONFIRM_MSG: 'Sobald die Vorlage gelöscht ist, kann sie nicht wiederhergestellt werden!',
  EVENT_CONFIRM_MSG:
    'Sobald die Veranstaltung archiviert ist, kann es nicht wiederhergestellt werden!',
  CREATE_DRAFT: 'Entwurf erstellen',
  CREATE_USER: 'Benutzer erstellen',
  ADD_ROLE: 'Rolle hinzufügen',
  DOMAIN_ADDRESS_INVALID_FOR: 'Domänenadresse ungültig für',
  AVAILABLE_ADDRESSES: 'Verfügbare Adressen',
  IN_TEAM: 'im Team',
  TO_THE_SELECTED_USER: 'an den ausgewählten Benutzer',
  USER_INFORMATION: 'Nutzerinformation',
  LOGIN_VERIFY_EMAIL_ADDRESS:
    'Die von Ihnen verwendete E -Mail -Adresse muss aktualisiert werden, da die Domänenadresse nicht dem Team entspricht, dem Ihr Profil zugeordnet ist.',
  MESSAGE: 'Nachricht',
  IN_APPROVAL: 'Mit Zustimmung',
  IN_REVIEW: 'In Bewertung',
  DOCUMENT_APPROVAL: 'Genehmigung der Dokument',
  DOCUMENT_TITLE: 'Dokumenttitel',
  SEARCH_DOCUMENTS: 'Suchdokumente',
  APPROVAL_INBOX: 'Posteingang für Genehmigung',
  FILTER_DOCUMENTS_BY: 'Filterdokumente von',
  submit_to_approval: 'Genehmigung einreichen',
  create_document_metadata: 'Erstellen Sie Dokumentmetadaten',
  archive: 'Archiv',
  I_ACKNOWLEDGE_THAT_IT_IS_:
    'Ich erkenne an, dass es meine Verpflichtung ist, alle unerwünschten Arzneimittelreaktionen (ADRs) aller medizinischen Produkte gemäß den lokalen Anforderungen zu melden. ENQMED ist nicht dazu gedacht, Berichte über unerwünschte Arzneimittelreaktionen zu erfassen - ADRs müssen direkt gemäß den lokalen Anforderungen an Pharmakovigilanz gemeldet werden',
  VERSION_DIALOG: {
    TITLE: 'Neue Version verfügbar',
    MESSAGE:
      'Es ist eine neue Version der Software verfügbar!\n Sie müssen sich die neue Version besorgen. Um von den darin enthaltenen Verbesserungen zu profitieren und Probleme bei der Verwendung der alten Version zu vermeiden, müssen Sie sie besorgen.',
    SAVE_BUTTON: 'Holen Sie sich die neue Version',
  },
  ACTIVE_INACTIVE: 'Aktiv inaktiv',
  CATEGORY_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Bitte überprüfen Sie die vorhandenen Kategorien in der Tabelle hinter dem Dialog, da wir bereits Kategorien mit denselben Werten haben.',
  ERROR_EMAIL_COULD_NOT_BE_DELIVERED: 'Fehler! Fehlgeschlagene Lieferung',
  VIA: 'über',
  INSERTED_MANUALLY: 'Manuell eingefügt',
  AS_A: 'Als ein',
  PRODUCT_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Bitte überprüfen Sie die vorhandenen Produkte in der Tabelle hinter dem Dialog, da wir bereits Produkte mit denselben Werten haben.',
  NOT_PRODUCT_SPECIFIC: 'Nicht produktspezifisch',
  TOPIC_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Bitte überprüfen Sie die vorhandenen Themen in der Tabelle hinter dem Dialog, da wir bereits Themen mit denselben Werten haben.',
  PLEASE_ENTER_MAX_CHARACTERS: 'Bitte geben Sie maximal {{ MAX_CHARS }} Zeichen ein.',
  THANK_YOU_MESSAGE: 'Dankesnachricht',
  AS: 'als',
  THERAPEUTIC_AREA_PARENT: 'Elternbereich',
  MAIN_THERAPEUTIC_AREA: 'Haupttherapeutischer Bereich',
  CHOOSE_AN_IMAGE: 'Wählen Sie ein Bild',
  ALLOWED_FORMATS: 'Nur {{ FORMATS }} Formate zulässig',
  REMOVE_IMAGE: 'Entferne Bild',
  THERAPEUTIC_AREA_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Bitte überprüfen Sie die vorhandenen Therapiebereiche in der Tabelle hinter dem Dialog, da wir bereits Bereiche mit denselben Werten haben.',
  PLAIN_TEXT_VIEW: 'Nur-Text-Ansicht',
  RICH_TEXT_VIEW: 'Rich-Text-Ansicht',
  EDIT_ITEM: 'Bearbeiten Sie {{ ITEM }}',
  CREATE_NEW_ITEM: 'Erstellen Sie ein neues {{ ITEM }}',
  ADD_NEW_ITEM: 'Neues {{ ITEM }} hinzufügen',
  ADD_ITEM: 'Fügen Sie {{ ITEM }} hinzu',
  AND_HENCE_STATUS_CHANGED_FROM: 'und daher änderte sich der Status von',
  FOLLOW_UP_WITH_ENQUIRER: 'Nachbereitung mit dem Anfragenden',
  _MUST_BE_INSERTED_TO_SAVE_REVIEW:
    'Um die Änderungen zu speichern, muss Folgendes eingefügt werden:',
  FOLLOW_UP_IS_NOT_POSSIBLE_FOR_CLOSED_INTERACTION:
    'Eine Nachverfolgung einer geschlossenen Interaktion ist nicht möglich.',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_REVIEW:
    'Interaktion im Bewertungsstatus. Bitte speichern Sie die Bewertung, um dieses Menü zu aktivieren.',
  QUESTION_TEXT_MIN_SIX_CHARS: 'Fragetext (mindestens 6 Zeichen)',
  PLEASE_PROVIDE_AN_ANSWER_:
    'Bitte geben Sie eine Antwort (mindestens 6 Zeichen oder einen Anhang).',
  USER_MUST_RECOVER_PASSWORD_MESSAGE:
    'Bitte beachten Sie, dass der Benutzer das Passwort unter „Passwort vergessen“ wiederherstellen muss.',
  ENABLE_USER_LOGIN_MESSAGE: 'Dadurch wird die Benutzeranmeldung aktiviert.',
  DISABLE_USER_LOGIN_AND_NOTIFICATIONS_MESSAGE:
    'Dadurch werden die Benutzeranmeldung und Benachrichtigungen deaktiviert.',
  SET_NOTIFICATIONS_PREFERENCES_MESSAGE:
    'Bitte legen Sie Ihre Benachrichtigungseinstellungen in Ihrem Benutzerprofil fest!',
  SET_PASSWORD: 'Bitte legen Sie Ihr Passwort fest',
  ERASED_PASSWORD_AFTER_ACCOUNT_REACTIVATION:
    'Nach der Reaktivierung Ihres Kontos wurde Ihr Passwort gelöscht.',
  SPECIALIZATION_DEACTIVATION_CONFIRMATION: 'Spezialisierungsdeaktivierung bestätigen!',
  REMOVE_DEACTIVATED_SPECIALIZATION_FROM_ACTIVE_LIST_MESSAGE:
    'Dadurch wird es aus der Liste mit aktiven Spezialisierungen entfernt.',
  GO_TO_ROLES: 'Gehen Sie zu Rollen',
  CONTACT_INFORMATION_USERNAME_CHANGE_MESSAGE:
    'Ihre Änderungen an den Kontaktinformationen des Benutzers führten zu einer Änderung der Login-E-Mail-Adresse von {{ OLD_USERNAME }} zu {{ NEW_USERNAME }}',
  ASSIGN_ROLE_AND_NOTIFICATIONS_MESSAGE: 'Bitte weisen Sie Rollen und Benachrichtigungen zu.',
  DO_YOU_WANT_TO_CONTINUE: 'Möchtest du fortfahren?',
  COPY_ANSWER_TO_NEW_INTERACTION: 'Kopieren Sie die Antwort auf die neue Interaktion',
  NEW_INQUIRIES_HAVE_BEEN_IMPORTED_MESSAGE:
    'Neue Anfragen wurden importiert. Stellen Sie sicher, dass Sie alle Anfragen charakterisieren und beantworten.',
  LEAVE_WITHOUT_SAVING: 'Gehen Sie, ohne zu speichern',
  PENDING_CHANGES_MESSAGE: 'Ausstehende Änderungen sind {{ CHANGES }}?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_QUESTION:
    'Sind Sie sicher, dass Sie den ausgewählten Text in einer neuen Interaktion teilen möchten?',
  SPLIT_SELECTED_TEXT_IN_NEW_INTERACTION_MESSAGE:
    'Dadurch wird der ausgewählte Text aus der Interaktion entfernt und daraus ein neuer erstellt.',
  DOWNLOAD_ERROR: 'Download Fehler!',
  DOWNLOAD_INQUIRIES_ERROR_MESSAGE:
    'Sie versuchen, über 1000 Anfragen herunterzuladen. Die maximal unterstützte Anzahl beträgt 1000. Bitte verfeinern Sie Ihre Auswahl.',
  TRYING_TO_CHANGE_QUESTION_CHARACTERIZATION:
    'Sie versuchen, die Charakterisierung der Frage zu ändern!',
  CHANGING_QUESTION_CHARACTERIZATION_NOT_ALLOW_CHANGES_IN_ANSWER_MESSAGE:
    'Bitte beachten Sie, dass eine Änderung der Fragecharakterisierung keine Änderung der zuvor gegebenen Antwort oder das Senden einer neuen Antwort zulässt!',
  RE_OPEN_CLOSED_INTERACTION_MESSAGE:
    'Sie sind dabei, eine geschlossene Interaktion erneut zu öffnen, um eine neue Antwort zu senden.',
  CONVERT_FOLLOWUP_TO_QUESTION_MESSAGE:
    'Möchten Sie dieses Follow-up wirklich in eine Frage umwandeln?',
  ARE_YOU_SURE: 'Bist du sicher?',
  USER_DEACTIVATION_CONFIRMATION_QUESTION: 'Deaktivierung von {{ USER_INFO }} bestätigen',
  USER_REACTIVATION_CONFIRMATION_QUESTION:
    'Bestätigen Sie die erneute Aktivierung von {{ USER_INFO }}',
  USER_REACTIVATED_MESSAGE: 'Benutzer {{ USER_INFO }} erfolgreich reaktiviert!',
  DELETE_NOT_POSSIBLE: 'Löschen nicht möglich!',
  SEARCH_NO_LONGER_EXIST: 'Diese Suche existiert nicht mehr, da sie vom Absender gelöscht wurde.',
  LOGIN_CREDENTIALS_CHANGED: 'Anmeldedaten geändert!',
  EDIT_TEXT: 'Text bearbeiten',
  FOLLOW_UP_ATTEMPTS: 'Folgeversuche',
  FIRST_FOLLOW_UP_INFO_TEXT: 'Erstes Follow-up im Rahmen der individuellen Interaktion gesendet',
  SECOND_AND_THIRD_FOLLOW_UP_INFO_TEXT:
    'Wenn einer der folgenden Punkte nach der ersten Anfrage deaktiviert wird, geht der Prozess zum nächsten Schritt über',
  SECOND_ATTEMPT: 'Zweiter Versuch (1. Erinnerung)',
  THIRD_ATTEMPT: 'Dritter Versuch (1. Mahnung)',
  FINAL_ATTEMPT: 'Für die Nachverfolgung verloren (schließen)',
  REMINDER_FREQUENCY: 'Erinnerungshäufigkeit',
  AUTO_TRANSLATE_FOLLOW_UP_TEMPLATE:
    'Wenden Sie eine automatische Übersetzung in die Sprache an, die zur Kontaktaufnahme mit dem Anfragenden verwendet wird',
  ON_OFF_SWITCH: 'An aus',
  DAY: 'Tag',
  DAYS: 'Tage',
  MONTH: 'Monat',
  MONTHS: 'Monate',
  RESET_TEMPLATES_TO_DEFAULTS: 'Setzen Sie die Vorlagen auf die Standardeinstellungen zurück',
  SAVE_FREQUENCY: 'Frequenz speichern',
  SELECT_FREQUENCY: 'Frequenz auswählen',
  TEMPLATE_MODIFICATIONS_HINT:
    'Der Text wurde manuell geändert und wird nicht mehr automatisch geändert. Klicken Sie hier, um die Änderungen zu verwerfen und automatische Updates wieder zu aktivieren.',
  LOGIN_INCORRECT_EMAIL_ADDRESS: 'Falsche E-Mail Adresse!',
  AUDIT_TRAIL_FOR: 'Audit-Trail für {{ ITEM }}',
  AUDIT_TRAIL_FOR_ALL: 'Audit-Trail für alle {{ ITEM }}',
  HISTORY_CHANGES_FOR: 'Geschichte ändert sich für',
  HOLIDAY_GDPR_DISCLAIMER: 'Haftungsausschluss zur Feiertags-DSGVO',
  MANAGE_HOLIDAY_GDPR_DISCLAIMER: 'Verwalten Sie den DSGVO-Haftungsausschluss für Feiertage',
  MISSING_HOLIDAY_GDPR_TEMPLATE_FOR_TEAMS:
    'Fehlende Feiertags-DSGVO-Vorlage für das folgende Team/die folgenden Teams',
  BULK_CREATE: 'Massenerstellung',
  LAST_SUCCESS: 'Letzter Erfolg',
  LAST_FAILURE: 'Letzter Fehler',
  FAILURE: 'Versagen',
  TASK: 'Aufgabe',
  Weeks: 'Wochen',
  REPEAT_EVERY: 'Wiederholen Sie dies bei jedem',
  DEACTIVATE_ITEM: '{{ ITEM }} deaktivieren',
  RESET_FAILURE_COUNT: 'Fehleranzahl zurücksetzen',
  TASK_DIALOG_VALIDATION_MESSAGE_FOR_DUPLICATE:
    'Bitte überprüfen Sie die vorhandenen Aufgaben in der Tabelle hinter dem Dialog, da wir bereits Aufgaben mit denselben Werten haben.',
  ACTIVATE_ITEM: 'Aktivieren Sie {{ ITEM }}',
  PLEASE_ENTER_MIN_NUMBER: 'Bitte geben Sie eine Zahl ein, die größer als {{ MIN_NUMBER }} ist.',
  MERGE_AS_NEW_INQUIRY: {
    DESCRIPTION_FOR_CLOSED_INTERACTION:
      'Die ausgewählte Zielinteraktion für die Zusammenführung ist bereits geschlossen. Diese Anfrage ist eine neue Interaktion und muss nicht zusammengeführt werden.',
    DESCRIPTION_FOR_OPEN_INTERACTION:
      'Eine neue Frage/n wird in die bestehende Anfrage eingefügt, alle Fragen müssen beantwortet werden. Bitte wählen Sie Fragen zum Zusammenführen aus.',
    VALIDATION_MSG:
      'Bitte wählen Sie mindestens eine Frage aus der zusammengeführten Interaktion aus, um mit der Zusammenführung fortzufahren.',
  },
  MERGED_INTERACTION: 'Zusammengeführte Interaktion',
  TARGET_INTERACTION: 'Zielinteraktion',
  MERGE_SUGGESTION: 'Vorschlag zusammenführen',
  PLEASE_REVIEW_MERGE_SUGGESTION_BELOW: 'Bitte lesen Sie den Zusammenführungsvorschlag unten',
  INQUIRERS_COMPARISON_TITLE:
    'Bitte beachten Sie unterschiedliche Anfragende für die zusammengeführte Interaktion und die Zielinteraktion',
  INQUIRERS_COMPARISON_QUESTION:
    'Bitte bestätigen Sie, ob Sie trotz dieser Differenz fortfahren möchten.',
  TERRITORY: 'Gebiet',
  DOMAIN: 'Domain',
  IN: 'In',
  TEAM_GROUP_FILTER: {
    TITLE: 'Definieren Sie Berechtigungen zum Anzeigen zusätzlicher Teams',
    SAVE_BTN_TOOLTIP:
      'Bitte füllen Sie zunächst alle Pflichtfelder für das Team aus und speichern Sie das Team.',
  },
  ADR_REF_ID: 'ADR-Referenz-ID',
  PQC_REF_ID: 'PQC-Referenz-ID',
  ATTACHMENT_PRESENT_IN_MULTIPLE_INTERACTIONS:
    'Diese Bindung ist in mehreren Interaktionen vorhanden',
  SELECT_CHECKBOX_TO_DELETE_ATTACHMENT_FROM_ALL_INTERACTIONS:
    'Bitte aktivieren Sie das Kontrollkästchen, wenn Sie den Anhang aus allen Interaktionen löschen möchten.',
  CONFIRM_DELETE_ATTACHMENT: 'Bitte bestätigen Sie, ob Sie diesen Anhang löschen möchten.',
  DELETE_FROM_ALL_INTERACTIONS_MENTIONED_ABOVE: 'Aus allen oben genannten Interaktionen löschen',
  ATTACHMENT_DELETED: 'Anhang gelöscht',
  ROLES: 'Rollen',
  SET_TO_ALL_INQ_TOOLTIP:
    'Legen Sie für alle Fragen die gleiche ADR-, PQC- und Off-Label-Charakterisierung fest.',
  TASKS_LIST: 'Aufgabenliste',
  TASK_LOGS: 'Aufgabenprotokolle',
  SELECTED: 'Ausgewählt',
  LICENSE_COUNTER: 'Lizenzzähler',
  LICENSES_LIMIT: 'Beschränkung der Lizenzanzahl',
  USERS_TO_EXCLUDE: 'Auszuschließende Benutzer',
  ENTER_MAX_NUMBER_OF_LICENSES: 'Vertraglich vereinbarte Lizenzgebühren (Benutzerrollen).',
  SELECT_USER_ROLES_TO_COUNT:
    'Wählen Sie Benutzerrollen aus, die für die Anzahl der Lizenzgebühren gelten.',
  SELECT_USERS_TO_EXCLUDE_FROM_COUNT: 'Schließen Sie Benutzer von der Lizenzgebührenzählung aus.',
  RECEIPT_AND_SALES_NOTIFICATIONS: 'Empfangs- und Verkaufsbenachrichtigungen',
  REFRESH_MIGRATION_ERRORS: 'Liste der Migrationsfehler aktualisieren',
  MARKED_AS_RESOLVED_BY: 'Als gelöst markiert von',
  MARKED_AS_RESOLVED_ON: 'Als gelöst markiert am',
  MARK_AS_RESOLVED: 'als gelöst markieren',
  ERROR_ON: 'Fehler an',
  TEXT_WITH_SPECIAL_CHARS:
    'Bitte geben Sie nur Buchstaben (a-z, A-Z), Zahlen (0-9) und Sonderzeichen wie Bindestrich (-), Punkt (.), Leerzeichen, Doppelpunkt (:), Komma (,), Klammern (()) und Vorwärts ein Schrägstrich (/) und Pluszeichen (+).',
  STATUS_DROPDOWN_TOOLTIP_TEXT_FOR_IN_APPROVAL:
    'Interaktion im Status „Zur Genehmigung eingereicht“. Bitte genehmigen/ablehnen Sie die Aktivierung dieses Menüs.',
  REFRESH_TASK_LOGS: 'Liste der Aufgabenprotokolle aktualisieren',
  LICENSE_COUNTER_ACTION_EDIT_CONFIRM_TITLE:
    'Bitte bestätigen Sie, ob Sie mit den Änderungen fortfahren möchten!',
  DEPUTY_ROLE_ARCHIVE_NOT_ALLOWED:
    'Für Stellvertreterrollen ist eine manuelle Archivierung nicht zulässig.',
  RUN_COUNTER: 'Zuwiderlaufen',
  EXPORT_LICENSES: 'Exportlizenzen',
  LICENSE_COUNT_MESSAGE: 'Die Anzahl der verwendeten Lizenzen beträgt:',
  QUESTIONS: 'Fragen',
  CLARIFICATION: 'Klärung',
  MERGE_AS_INQUIRY: {
    VALIDATION_MSG:
      'Bitte wählen Sie mindestens eine Frage in der Zielinteraktion aus, um mit der Zusammenführung fortzufahren.',
    DESCRIPTION:
      'Die klärende Frage wird unter den ausgewählten Fragen in der Zielinteraktion hinzugefügt.',
  },
  MERGE_AS_DUPLICATE: {
    DESCRIPTION:
      'Wählen Sie diese Option aus, um die Interaktion als Duplikat der vorhandenen Interaktion zu kennzeichnen.',
  },
  DETECTED_CLARIFICATION_QUESTION_WITHOUT_ANSWER:
    'Vorherige Zusammenführung als Klärungsfrage erkannt. Alle ausgewählten Antwortfelder zusätzlich zu den zuvor ausgewählten Antwortfeldern in der Zielinteraktion werden erneut zur Beantwortung geöffnet.',
  DISABLED_ANSWER_FIELD_TOOLTIP:
    'Die Interaktion wurde erneut geöffnet, um eine Antwort auf eine andere Frage zu geben. Die Antwort auf diese Frage wurde bereits zuvor gegeben.',
  TIP: 'Tipp',
  MERGE_TIP_TEXT:
    'Wenn Sie stattdessen die Interaktion {{ MERGED_INTO_ID }} erneut öffnen möchten, drücken Sie bitte Nein und initiieren Sie einen neuen Zusammenführungsprozess mit der Zielinteraktion {{ MERGED_INTO_ID }}.',
  YOU_ARE_SELECTING_MERGE_WITHOUT_REOPEN:
    'Sie wählen einen Zusammenführungsvorgang aus, ohne die Zielinteraktion zu öffnen.',
  YOU_ARE_SELECTING_MERGE_INTO_INVALID_INTERACTION:
    'Sie wählen einen Zusammenführungsvorgang für eine ungültige Zielinteraktion {{ TARGET_INTERACTION_ID }} aus.<br>Die Interaktion {{ TARGET_INTERACTION_ID }} wurde mit dem Status {{ STATUS }}{{ LAST_MERGE_INFO }} geschlossen.',
  CLOSED_INTERACTION_AND_INABILITY_TO_PROVIDE_ANSWER:
    'Dies führt zu einer geschlossenen Interaktion und der Unfähigkeit, eine Antwort zu geben.',
  INTO_INTERACTION: 'in Interaktion',
  PLEASE_SELECT_INTERACTION_FOR_MERGE: 'Bitte wählen Sie die Interaktion zum Zusammenführen aus.',
  PLEASE_SELECT_MERGE_REASON: 'Bitte wählen Sie den Zusammenführungsgrund aus.',
  QUESTION_CANNOT_BE_DELETED:
    'Diese Frage kann nicht gelöscht werden, da es eine zugehörige unbeantwortete Klärungsfrage gibt.',
  MERGE_REASON: 'Grund für die Zusammenführung',
  WITH_REOPEN: 'mit Wiedereröffnung',
  MERGED_INTO_QUESTION_IDS: 'in Frage-ID(s) zusammengeführt',
  MERGED_INTO_QUESTIONS: 'in Frage(n) zusammengeführt',
  MERGED_PREVIOUSLY_FROM: 'zuvor zusammengeführt von',
  FROM_INTERACTION_ID: 'aus der Interaktions-ID',
  IN_INTERACTION_ID: 'in der Interaktions-ID',
  YOU_ARE_ABOUT_TO_CANCEL_MERGE_SUGGESTION:
    'Sie sind dabei, den Zusammenführungsvorschlag abzubrechen.',
  DO_NOT_SHOW_MERGE_SUGGESTION_AGAIN: 'Zusammenführungsvorschlag nicht mehr anzeigen?',
  PRODUCT_RESOLVED_VIA_AI: 'Produkt (über AI gelöst)',
  REQUIRED_FIELDS_HINT: 'Bitte füllen Sie alle erforderlichen Felder aus',
  AT_LEAST_ONE_CONTACT_IS_REQUIRED: 'Es ist mindestens ein Kontakt erforderlich!',
  DISCLAIMER_INFO: 'Informationen zum Haftungsausschluss',
  QUESTION_ID: 'Frage-ID',
  QUESTION_IDS: 'Frage-IDs',
  SHOW_TEAMS: 'Teams anzeigen',
  LAST_POLLING: 'Letzte Umfrage',
  HOST: 'Gastgeber',
  PROTOCOL: 'Protokoll',
  FROM_EMAIL: 'Aus der Email',
  REASON_FOR_REMOVAL: 'Grund für die Entfernung',
  A_CLOSED_INTERACTION_IS_NOT_EDITABLE:
    'Eine geschlossene Interaktion kann nicht bearbeitet werden.',
  METADATA_DROPDOWNS_TOOLTIP_TEXT:
    'Bei der Interaktion gibt es mehr als eine Frage. Bearbeitung nur über offene Interaktion möglich.',
  BULK_EDIT: 'Bulk-Bearbeitung',
  HOW_TO_ENABLE_BULK_EDIT:
    'Um eine Massenaktualisierung durchzuführen, müssen mindestens zwei Vorlagen in derselben Sprache ausgewählt werden.',
  REASON_FOR_UPDATE: 'Grund für die Aktualisierung',
  FOLLOW_UP_RESEND: 'Follow-up erneut gesendet',
  SENT_FOLLOW_UP_REMINDER: 'Folgeerinnerung gesendet',
  CLOSED_INTERACTION_AND_SENT_LOST_TO_FOLLOW_UP_NOTIFICATION:
    'Interaktion geschlossen und verloren zur Folgebenachrichtigung gesendet',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS:
    'Bitte geben Sie Text mit einer Mindestlänge von 15 Zeichen ein.',
  FOLLOW_UP_DISABLED_INSERT_ENQUIRER_FIRST:
    'Nachverfolgung deaktiviert! Bitte geben Sie zuerst den Anfragenden ein.',
  FOLLOW_UP_REQUEST_DISABLED_INSERT_ENQUIRER_EMAIL_FIRST:
    'Folgeanfrage deaktiviert! Bitte geben Sie zuerst eine gültige E-Mail des Anfragenden ein.',
  FOLLOW_UP_FREQUENCY_VALIDATION_MSG:
    'Die Zahl für den ausgewählten Frequenztyp sollte zwischen 1 und {{ MAX_ALLOWED_FREQUENCY }} liegen.',
  FOLLOW_UP_PLEASE_SELECT_FREQUENCY_TYPE: 'Bitte wählen Sie den Frequenztyp.',
  FOLLOW_UP_FREQUENCY_TOOLTIP:
    'Die Zählung beginnt mit dem Datum und der Uhrzeit der ersten Folgeanfrage. Zur Umrechnung von Tagen in Wochen multiplizieren Sie mit 7, z. B. 2 Wochen = 14 Tage, zur Umrechnung von Tagen in Monate multiplizieren Sie mit 30, z. B. 1 Monat = 30 Tage.',
  VALID_PHONE_FAX: 'Bitte geben Sie eine gültige Telefon-/Faxnummer ein!',
  SELECT_LANG_TO_VIEW_AND_MANAGE_TRANSLATED_TEMPLATES:
    'Wählen Sie eine Sprache aus, um die übersetzten Vorlagen anzuzeigen und zu verwalten',
  RETRANSLATE: 'Erneut übersetzen',
  FOLLOW_UP_REMINDERS_INFO_TEXT:
    'Die folgenden Vorlagen sind nur in englischer Sprache verfügbar. Übersetzungen werden unten verwaltet.',
  FOLLOW_UP_RESET_BTN_TOOLTIP:
    'Alle oben aufgeführten Vorlagen werden auf die Standardeinstellungen zurückgesetzt (manuelle Änderungen werden entfernt, die Häufigkeit auf 14 Tage zurückgesetzt und das Kontrollkästchen „Übersetzung“ deaktiviert), in alle Sprachen neu übersetzt und „is_manually_modified“ wird gelöscht.',
  FOLLOW_UP_EDIT_BTN_TOOLTIP:
    'Bearbeiten Sie den Vorlagentext und übersetzen Sie alle Übersetzungen erneut, die nicht manuell geändert wurden.',
  FOLLOW_UP_RETRANSLATE_BTN_TOOLTIP:
    'Übersetzen Sie den ursprünglichen Vorlagentext erneut, einschließlich der manuell geänderten Texte.',
  FOLLOW_UP_REMINDER_TEMPLATES: 'Vorlagen für Folgeerinnerungen',
  MANUALLY_MODIFIED: 'Manuell geändert',
  SYNC: 'Synchronisieren',
  DIFFERENT: 'Anders',
  DEFAULT_CONFIRMATION_QUESTION: 'Bitte bestätigen Sie, ob Sie fortfahren möchten.',
  SEND_WELCOME_MAIL_: 'Willkommens-E-Mail mit Passwortzurücksetzung senden',
  CONFIRM_FU_RESPONSE_WITH_REOPEN:
    'Ich bestätige, dass der Anfragende neue Folgeinformationen erhalten hat und dass ich die Interaktion zur Bereitstellung einer Antwort erneut öffnen möchte.',
  ONLY_LETTERS_SPACE_AND_DASH: 'Bitte nur Buchstaben, Leerzeichen und -Zeichen eingeben!',
  INFO: 'Die Info',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG:
    'Eine Änderung des Landes des Anfragenden ändert die Zuweisung der Interaktion vom Team {{ CURRENT_TEAM }} zum Team {{ FUTURE_TEAM }}.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_REMOVE_ASSIGNMENT:
    'Eine Änderung des Landes des Anfragenden ändert die Zuweisung der Interaktion vom Team {{ CURRENT_TEAM }} zum Team {{ FUTURE_TEAM }}. Der Benutzer {{ ASSIGNED_TO }} hat keine Berechtigungen für das Team {{ FUTURE_TEAM }} und seine persönliche Zuweisung wird entfernt.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'Die Änderung wirkt sich auf Teamzuweisungen in den folgenden zusätzlichen Interaktionen aus, wobei persönliche Zuweisungen entfernt werden: {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_HCP_COUNTRY_CHANGE_CONFIRM_MSG_FOR_RELATED_INTERACTIONS:
    'Eine Änderung des Landes des Anfragenden ändert die Teamzuweisungen in den folgenden zusätzlichen Interaktionen, wobei die persönliche Zuweisung entfernt wird. {{ RELATED_INTERACTIONS_TEAMS_AND_USERS }}',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_RELATED_INTERACTIONS_DATA:
    'Interaktions-ID: {{ INTERACTION_ID }} von {{ CURRENT_TEAM }} an {{ FUTURE_TEAM }}, persönliche Zuweisung vom Benutzer {{ ASSIGNED_TO }} entfernt.',
  INQUIRER_INTERACTION_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'Der Benutzer {{ EDITING_USER }} hat das Land des Anfragenden {{ ENQUIRER }} in der Interaktions-ID: {{ UPDATED_INTERACTION_ID }} geändert, wodurch die Zuweisung der aktuellen Interaktion vom Team {{ OLD_TEAM }} zum Team {{ NEW_TEAM }} geändert wurde.',
  INQUIRER_HCP_COUNTRY_CHANGE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'Der Benutzer {{ EDITING_USER }} hat das Land des Anfragenden {{ ENQUIRER }} in der HCP-Verwaltung geändert, wodurch die Zuweisung der aktuellen Interaktion vom Team {{ OLD_TEAM }} zum Team {{ NEW_TEAM }} geändert wurde.',
  INQUIRER_COUNTRY_CHANGE_ADDITIONAL_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'Der Benutzer {{ INTERACTION_ASSIGN_TO }} verfügt nicht über Berechtigungen für das Team {{ NEW_TEAM }} und seine persönliche Zuweisung wurde entfernt.',
  PERSONAL_ASSIGNMENT: 'Persönlicher Auftrag',
  DUE_TO_FU_REQUEST: 'aufgrund einer Folgeanfrage',
  DUE_TO_FU_RESPONSE: 'aufgrund der Folgeantwort',
  DUE_TO_FU_REMINDERS_FREQ_UPDATE:
    'aufgrund der Aktualisierung der Häufigkeit von Folgeerinnerungen auf der Admin-Seite',
  UPDATED_FROM_INBOX: 'aus dem Posteingang aktualisiert',
  ARCHIVED_ROLE: 'Archivierte Rolle',
  USER_ROLE_ARCHIVE_CONFIRM_MSG_INTERACTIONS_DATA:
    'Durch das Archivieren der Benutzerrolle werden Zuweisungen in den folgenden zusätzlichen Interaktionen geändert, wobei persönliche Zuweisungen entfernt werden: {{ USER_INTERACTIONS }}',
  USER_ROLE_ARCHIVE_INFO_MSG_FOR_RELATED_INTERACTIONS:
    'Der Benutzer {{ EDITING_USER }} hat den Benutzer {{ INTERACTION_ASSIGN_TO }} bearbeitet und seine Rolle als {{ USER_ROLE }} im Team {{ TEAM }} archiviert. Der Benutzer {{ INTERACTION_ASSIGN_TO }} verfügt nicht über Berechtigungen für das Team {{ TEAM }} und seine persönliche Zuweisung wurde entfernt.',
  ASSIGNMENT: 'Abtretung',
  PERIOD_TO_PERIOD_OVERLAP: 'Zeitraum und Bis-Zeitraum überschneiden sich',
  PERIOD_TO_PERIOD_MISMATCH:
    'Periode und Bis-Periode haben unterschiedliche Dauer - {{ PERIOD }} {{ INTERVAL }} und {{ TO_PERIOD }} {{ TO_INTERVAL }}',
  CREATED_QUESTION_FROM_FU_RESPONSE: 'Frage aus Folgeantwort erstellt',
  CREATE_QUESTION_FROM_FU_RESPONSE_TOOLTIP:
    'Mit dieser Option können Sie aus einer Folgefrage eine neue Frage erstellen.',
  ADD_AS_A_NEW_QUESTION: 'Als neue Frage hinzufügen',
  ROLE_ARCHIVE_WITH_USER_DEACTIVATION:
    'Bitte beachten Sie, dass Sie versuchen, die letzte genehmigte Rolle für diesen Benutzer zu archivieren, was zur Deaktivierung des Benutzers führt und seinen weiteren Zugriff auf EnqMed verhindert.',
  ATTEMPTED_FOLLOW_UP: 'versuchte eine Fortsetzung',
  FAILED_FU_ATTEMPT: 'Fehlgeschlagener Folgeversuch',
  UNABLE_TO_REACH_ENQUIRER: 'Der Anfragende konnte nicht erreicht werden.',
  FAILED_FU_ATTEMPT_TOOLTIP:
    'Wählen Sie diese Option, wenn versucht wurde, den Anfragenden zu erreichen, dies jedoch erfolglos war, z. B. wenn keine telefonische Erreichbarkeit möglich war, ein persönlicher oder anderer Termin fehlgeschlagen ist.\nDer Versuch wird im Interaktionsverlauf aufgezeichnet.',
  REDIRECT_TO_PAGE_WITH_PERMISSION_ACCESS:
    'Sie werden auf eine Seite weitergeleitet, für die Sie über Berechtigungen verfügen.',
  FU_STOPPED_DUE_TO_ENQ_CHANGE:
    'aufgrund eines Abbruchs des Folgeprozesses infolge eines Wechsels des Anfragenden',
  YOU_ARE_ABOUT_TO_SWITCH_ENQ_AND_STOP_FU:
    'Sie sind dabei den Anfragenden zu wechseln, wodurch der Folgeprozess beendet wird.',
  INQUIRER_DETAILS: 'Anfragedetails',
  INQUIRER_TYPE: 'Anfragetyp',
  DELETE_INQUIRER_PII: 'Persönliche Informationen des Anfragenden löschen',
  MARKED_INQUIRER_PII_DELETE: 'markierte persönliche Informationen des Anfragenden zur Löschung',
  UNMARKED_INQUIRER_PII_DELETE: 'unmarkierte personenbezogene Daten des Anfragenden zur Löschung',
  NEW_ENQUIRER: 'Neuer Fragesteller',
  NEW_ENQUIRER_TOOLTIP:
    'Verwenden Sie diese Option, um einen komplett neuen Anfragenden anzulegen. Wenn stattdessen eine Bearbeitung der vorhandenen Anfragendendaten erforderlich ist, verwenden Sie diese Option nicht.',
  CONFIRM_SET_NEW_ENQUIRER:
    'Bitte bestätigen Sie, dass Sie den bestehenden Anfragenden durch einen neuen ersetzen möchten.<br>Alle eingegebenen Informationen gehen dabei verloren.<br>Wenn Sie stattdessen die Daten des bestehenden Anfragenden bearbeiten möchten, ändern Sie die Daten direkt im Formular und drücken Sie Speichern.',
  INQUIRER: 'Anfrage',
  KEEP_INQUIRER_DETAILS_OPEN: 'Halten Sie die Anfragedetails offen',
  LOST_UNSAVED_CHANGES_ON_INTERACTION_CLOSE_MESSAGE:
    'Sie verfügen über ungespeicherte Änderungen, welche beim Schließen der Anfragenden-Details verloren gehen würden.',
  SHARE_FROM_HERE_YOU_CAN_:
    'Teilen: Von hier aus können Sie die Anfrage zuweisen, weiterleiten oder ein Follow-up mit dem Anfragenden starten.',
  UPDATED_INQUIRER_DETAILS: 'aktualisierte Anfragerdetails',
  CREATED_INQUIRER: 'erstellter Fragesteller',
  SUGGESTED_INQ: 'Vorgeschlagene Anfragen',
  SELECT_INQUIRER: 'Anfrage auswählen',
  ADR_OBLIGATION_CONSENT_AND_INQUIRER_:
    'ADR-Verpflichtung Zustimmung und Anfragender müssen ausgewählt werden, um die Interaktion zu übermitteln',
  ANSWER_HAS_NOT_BEEN_SENT_TO_THE_INQ_:
    'Aufgrund einer falschen E-Mail-Adresse konnte die Antwort nicht an den Anfragenden gesendet werden.',
  MSG_NOTIFICATION_WILL_BE_SEND_1:
    'Vielen Dank! Die Benachrichtigung wird gesendet, sobald die Anfrage übermittelt oder beantwortet wurde.',
  MSG_NOTIFICATION_WILL_BE_SEND_2:
    'Vielen Dank! Die Benachrichtigung wird gesendet, sobald die Anfrage gespeichert wurde.',
  NOTIFICATION_WILL_BE_SENT_TO_THE_INQ:
    'Die folgende DSGVO-Benachrichtigung wird dem Anfragenden per E-Mail zugesandt.',
  NOTIFICATION_SENT_TO_THE_INQ:
    'Die folgende DSGVO-Benachrichtigung wurde dem Anfragenden per E-Mail gesendet.',
  SEND_A_NOTIFICATION_TO_THE_INQ:
    'Senden Sie dem Anfragenden folgende DSGVO-Benachrichtigung per E-Mail.',
  INQ_HAS_NO_EMAIL: 'Der Anfragende hat keine E-Mail.',
  A_NOTIFICATION_HAS_NOT_BEEN_TO_INQ:
    'Dem Anfragenden wurde KEINE Benachrichtigung über den Eingang der Anfrage gesendet.',
  NOTIFICATION_SENT_TO_THE_INQUIRER:
    'Der Anfragende erhält eine Benachrichtigung, dass die Anfrage eingegangen ist.',
  THIS_IS_JUST_A_THANK_YOU:
    'Dies ist lediglich eine Dankesnachricht des Anfragenden der bestehenden Anfrage.',
  SAVE_INQUIRER: 'Anfrage speichern',
  INQ_DEFAULT_VAL: 'Enquirer-Standardwerte',
  COMPANY_EMPLOYEE_SUBMISSION_TOOLTIP:
    'Bei Anfragen, die über einen Unternehmensvertreter (Handelsvertreter, MSL oder andere) eingereicht werden, informieren Sie den Anfragenden über die Verarbeitung seiner personenbezogenen Daten.',
  SELECT_PREFERRED_CONTACT_ERROR:
    'Sie müssen einen bevorzugten Kontakt für den Anfragenden auswählen.',
  FOLLOW_UP_INITIATED_BY_INQUIRER: 'Vom Anfragenden initiierte Nachverfolgung',
  NO_INQUIRER: 'Kein Fragesteller',
  'Changed inquirer': 'Geänderter Fragesteller',
  DELETE_INQUIRY_MESSAGE:
    'Sie sind dabei, diese Anfrage zu löschen. Diese Aktion kann nicht rückgängig gemacht werden.',
  QUESTION_REQUIRE_ANSWER_MESSAGE:
    'Um die Anfrage abzuschließen, muss die neue Frage beantwortet werden.',
  IMAGE: 'Bild',
  THERAPEUTIC_AREA_STATUS_MESSAGE: 'Der Therapiebereich ist {{ STATUS }}',
  THERAPEUTIC_AREA_SELECT_PRODUCT_TO_ACTIVATE:
    ', da keine zugehörigen Produkte vorhanden sind. Bitte wählen Sie mindestens ein Produkt aus, um den Therapiebereich zu aktivieren.',
  EXPAND_ALL_THERAPEUTIC_AREAS: 'Erweitern Sie alle Therapiebereiche',
  COLLAPSE_ALL_THERAPEUTIC_AREAS: 'Alle Therapiebereiche ausblenden',
  THERAPEUTIC_AREA_EDIT_PARENT_HINT:
    'Um einen Therapiebereich (TA) als Haupt-TA festzulegen, heben Sie die Auswahl des übergeordneten TA auf und speichern Sie.',
  EXTRA_STEP_REQUIRED_TO_LOGIN: 'Zusätzlicher Schritt zur Anmeldung erforderlich',
  EXTRA_STEP_DESCRIPTION:
    'Eine Anmeldebestätigung wurde an Ihre E-Mail-Adresse gesendet. Bitte folgen Sie den darin enthaltenen Schritten, um zum System zu gelangen.',
  ENTER_TEXT_WITH_MIN_LEN_15_CHARS_OR_ATTACHMENT:
    'Bitte geben Sie einen Text mit einer Mindestlänge von 15 Zeichen oder einen Anhang ein.',
  REASON_FOR_REJECTION: 'Grund für die Ablehnung',
  NOTE_PROPERLY_INSERTED_IN_HISTORY: 'Die Notiz wurde ordnungsgemäß in den Verlauf eingefügt.',
  METADATA_ACTION_EDIT_CONFIRM_TITLE:
    'Ab {{ ACTION_TYPE }} dem ausgewählten {{ METADATA_TYPE }} werden die folgenden zusätzlichen zugehörigen Änderungen wirksam:',
  THERAPEUTIC_AREA_ACTION_EDIT_CONFIRM_TITLE:
    'Durch die Entfernung der Produkte ergeben sich folgende zusätzliche damit verbundene Änderungen:',
  TEAM_NOTIFY_INQUIRER: 'Anfragenden benachrichtigen',
  TEAM_NOTIFY_INQUIRER_TOOLTIP:
    'Wenn Sie diese Option aktivieren, werden automatisch DSGVO-Benachrichtigungen an Anfragende in offenen Interaktionen in diesem Team gesendet, die noch nicht benachrichtigt wurden.',
  TEAM_NOTIFY_SALES: 'Verkauf benachrichtigen',
  TEAM_NOTIFY_SALES_TOOLTIP:
    'Durch Aktivieren dieser Option wird die Benachrichtigung „Antwort erforderlich“ für alle Benutzer mit der Vertriebsrolle in diesem Team aktiviert.\n\nDurch Deaktivieren dieser Option wird die Benachrichtigung „Antwort erforderlich“ für alle Benutzer mit der Vertriebsrolle in diesem Team deaktiviert, es sei denn, sie haben die Vertriebsrolle in einem anderen Team, in dem die Einstellung „Vertrieb benachrichtigen“ aktiviert ist.',
  RECEIVES_THE_FOLLOWING_NOTIFICATION: 'erhält folgende Benachrichtigung',
  EMAILS: 'E-Mails',
  PHONES: 'Telefone',
  USERS_DEACTIVATED_SUCCESSFULLY: 'Benutzer erfolgreich deaktiviert',
  CONFIRM_BULK_DEACTIVATION_OF_USERS: 'Massendeaktivierung von Benutzern bestätigen',
  DEACTIVATE_SELECTED_USERS: 'Ausgewählte Benutzer deaktivieren',
  SELECT_USERS_TO_DEACTIVATE: 'Zu deaktivierende Benutzer auswählen',
  ARCHIVE_FOLDER: 'Archivordner',
  EXTRA_PARAMS:
    'Zusätzliche Parameter (k1=v1&k2=v2, „Ordner“ und „Archiv“ sind reserviert und können nicht verwendet werden)',
  POLL_FOLDER: 'Umfrageordner',
  POLL_FOLDERS_TEXT:
    'Umfrageordner können nur für die Protokolle IMAP oder Gmail eingerichtet werden. Für alle anderen Protokolle ist der Posteingang die Standardeinstellung.',
  POLL_FOLDERS_TOOLTIP_1:
    'Geben Sie den Ordnernamen ein und drücken Sie die Eingabetaste oder fügen Sie ihn durch ein Komma zum Abrufen aus dem Postfach hinzu (wenn kein Ordner hinzugefügt wird, wird standardmäßig der Posteingang abgefragt).',
  POLL_FOLDERS_TOOLTIP_2: 'Die hier aufgeführten Ordnernamen werden aus dem Postfach abgefragt.',
};
